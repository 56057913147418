<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav :key="reloader"/>


  <main>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">
        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">

            
            <form @submit.prevent="saveProfile()" class="space-y-8 divide-y divide-gray-200">
              <div class="space-y-8 divide-y divide-gray-200">


                <div>
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ $t('profile.your_profile')}}
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ $t('profile.your_profile_info')}}
                    </p>
                  </div>
                  
                  <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                      <label for="first-name" class="block text-sm font-medium text-gray-700">
                        {{ $t('profile.firstname')}}
                      </label>
                      <div class="mt-1">
                        <input v-model="user.firstname" type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label for="last-name" class="block text-sm font-medium text-gray-700">
                        {{ $t('profile.lastname')}}
                      </label>
                      <div class="mt-1">
                        <input v-model="user.lastname" type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label for="email" class="block text-sm font-medium text-gray-700">
                        {{ $t('profile.email')}}
                      </label>
                      <div class="mt-1">
                        <input v-model="user.email" id="email" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label for="phone" class="block text-sm font-medium text-gray-700">
                        {{ $t('profile.phone')}}
                      </label>
                      <div class="mt-1">
                        <input v-model="user.phone" id="phone" name="phone" type="tel" autocomplete="tel" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>


                    <div class="sm:col-span-6">
                      <label for="photo" class="block text-sm font-medium text-gray-700">
                        {{ $t('profile.profile_picture')}}
                      </label>
                      <div class="mt-1 flex items-center">
                        <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                          
                          <img v-if="user.profile_picture_preview" :src="user.profile_picture_preview" class="h-full w-full text-gray-300" />
                          <img v-if="user.profile_picture !== null" :src="$API_URL+'/uploads/'+user.profile_picture" class="h-full w-full text-gray-300" >
                          <svg v-if="user.profile_picture === null && !user.profile_picture_preview" class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                          
                        </span>
                        <label for="file-upload">
                          <span class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 cursor-pointer focus:ring-offset-2 focus:ring-indigo-500">{{ $t('profile.change_profile_picture')}}</span>
                          <input id="file-upload" name="file-upload" type="file" class="sr-only" ref="profile_picture_file" v-on:change="handleFileUpload()">
                        </label>
                        <button @click="saveProfilePicture()" v-if="user.profile_picture_preview" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          {{ $t('buttons.save') }}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="pt-8">
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ $t('profile.notifications')}}
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ $t('profile.notification_info')}}
                    </p>
                  </div>
                  <div class="mt-6">
                    <fieldset>
                      <legend class="text-base font-medium text-gray-900">
                        {{ $t('profile.by_email')}}
                      </legend>
                      <div class="mt-4 space-y-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="user.notification_general" id="comments" name="comments" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="comments" class="font-medium text-gray-700">{{ $t('profile.general_notifications')}}</label>
                            <p class="text-gray-500">{{ $t('profile.general_notifications_info')}}</p>
                          </div>
                        </div>
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="user.notification_warnings" id="candidates" name="candidates" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="candidates" class="font-medium text-gray-700">{{ $t('profile.warning_notifications')}}</label>
                            <p class="text-gray-500">{{ $t('profile.warning_notifications_info')}}</p>
                          </div>
                        </div>
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="user.notification_offers" id="offers" name="offers" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="offers" class="font-medium text-gray-700">{{ $t('profile.offer_notifications')}}</label>
                            <p class="text-gray-500">{{ $t('profile.offer_notifications_info')}}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset class="mt-6">
                      <div>
                        <legend class="text-base font-medium text-gray-900">
                          {{ $t('profile.by_sms')}}
                        </legend>
                        <p class="text-sm text-gray-500">{{ $t('profile.by_sms_info')}}</p>
                      </div>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input v-model="user.notification_sms" value="everything" id="push-everything" name="push-notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                          <label for="push-everything" class="ml-3 block text-sm font-medium text-gray-700">
                            {{ $t('profile.everything')}}
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input v-model="user.notification_sms" value="same" id="push-same" name="push-notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                          <label for="push-same" class="ml-3 block text-sm font-medium text-gray-700">
                            {{ $t('profile.same_as_email')}}
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input v-model="user.notification_sms" value="none" id="push-none" name="push-notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                          <label for="push-none" class="ml-3 block text-sm font-medium text-gray-700">
                            {{ $t('profile.no_sms')}}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div class="pt-5">
                <div class="flex justify-end">
                  <button @click="logout" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    {{ $t('nav.logout')}}
                  </button>
                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ $t('buttons.save')}}
                  </button>
                </div>
              </div>
            </form>
            

          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <Footer />
    <Notification  v-if="showNotification"  @hide="showNotification = false" v-bind:title="notificationTitle" v-bind:success="notificationSuccess" v-bind:message="notificationMessage" />
  </main>
</div>
</template>

<script>

import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import Notification from '../layout/Notification.vue'

export default {
  name: 'HelloWorld',
  components: {
    TopNav,
    Footer,
    Notification
  },
  data() {
    return {
      showNotification: false,
      notificationTitle: 'request_success',
      notificationMessage: 'new_data_collector_created',
      notificationSuccess: true,
      reloader: 0,
      user: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        profile_picture: null,
        profile_picture_file: null,
        profile_picture_preview: null,
        notification_general: false,
        notification_warnings: false,
        notification_offers: false,
        notification_sms: null
      }
    }
  },
  mounted() {
    this.getProfile()
  },
  setup() {
    
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },
    getProfile() {
      this.$API.getData('/profile', {})
        .then(response => {
          this.user.firstname = response.data.firstname;
          this.user.lastname = response.data.lastname;
          this.user.email = response.data.email;
          this.user.phone = response.data.phone;
          this.user.profile_picture = response.data.profile_picture;
          this.user.notification_general = response.data.notification_general;
          this.user.notification_warnings = response.data.notification_warnings;
          this.user.notification_offers = response.data.notification_offers;
          this.user.notification_sms = response.data.notification_sms;

          localStorage.setItem('user', JSON.stringify(response.data));

          this.reloader++;
        });
    },
    handleFileUpload() {
      this.user.profile_picture_file = this.$refs.profile_picture_file.files[0];
      this.user.profile_picture_preview = URL.createObjectURL(this.user.profile_picture_file);
    },
    saveProfilePicture() {
      let file = this.user.profile_picture_file;
      let formData = new FormData();
      formData.append('file', file);
      let url = new URL(this.$API_URL+'/profile')
      url.search = new URLSearchParams({token: localStorage.getItem('token')}).toString();
      fetch(url, { // Your POST endpoint
          method: 'POST',
          headers: {
            'Accept': 'application/json'
          },
          body: formData // This is your file object
        }).then(response => {
          if(response.status == 200) {
            this.user.profile_picture_preview = null;
            this.getProfile();
            this.showNotification = true;
            this.notificationMessage = 'information_updated';
          }
         
        }).catch(
          error => console.log(error) // Handle the error response object
        );
    },
    saveProfile() {
      this.$API.postData('/profile', this.user)
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          console.log(response.data);
        });
    }
  }
  
}
</script>