<template>
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Från
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Till
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tid
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">{{$t('buttons.change')}}</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="stop in stops" v-bind:key="stop.stop_from">
              <td class="px-6 py-4 whitespace-nowrap">
                <svg xmlns="http://www.w3.org/2000/svg" :class="stop.ongoing ? 'text-red-600' : 'text-gray-600'" class="h-10 w-10 rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ datetimeToTime(stop.stop_from) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ datetimeToTime(stop.stop_to) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{
                  diffDate(stop.stop_from, stop.stop_to)
                }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a  href="#" class="text-indigo-600 hover:text-indigo-900">{{$t('buttons.change')}}</a>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'StopList',
    props: ['stops'],
    data() {
        return {
        }
    },
    mounted() {
      
      console.log(this.stops);
    },
    methods: {
      diffDate(from, to) {
       
        var a = moment(from)
        var b = moment(to)
        var ss = b.diff(a, 'seconds');

        let parts = [];
        const duration = moment.duration(ss, 'seconds');

        if(!duration || duration.toISOString() === "P0D") return;

        if(duration.years() >= 1) {
            const years = Math.floor(duration.years());
            parts.push(years+" "+(years > 1 ? this.$t('general.years') : this.$t('general.year')));
        }

        if(duration.months() >= 1) {
            const months = Math.floor(duration.months());
            parts.push(months+" "+(months > 1 ? this.$t('general.months') : this.$t('general.month')));
        }

        if(duration.days() >= 1) {
            const days = Math.floor(duration.days());
            parts.push(days+" "+(days > 1 ? this.$t('general.days') : this.$t('general.day')));
        }

        if(duration.hours() >= 1) {
            const hours = Math.floor(duration.hours());
            parts.push(hours+" "+(hours > 1 ? this.$t('general.hours') : this.$t('general.hour')));
        }

        if(duration.minutes() >= 1) {
            const minutes = Math.floor(duration.minutes());
            parts.push(minutes+" "+(minutes > 1 ? this.$t('general.minutes') : this.$t('general.minute')));
        }

        if(duration.seconds() >= 1) {
            const seconds = Math.floor(duration.seconds());
            parts.push(seconds+" "+(seconds > 1 ? this.$t('general.seconds') : this.$t('general.second')));
        }

        return parts.join(", ");
      },
      datetimeToTime(datetime) {
        return moment(datetime).format("HH:mm:ss")
      }
    }
}
</script>