<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav/>


  <main>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">

        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">

            <div class="lg:flex lg:items-center lg:justify-between mb-5 pb-5 border-b border-gray-200">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{ current.is_customer ? 'Anläggning' : current.name }}  - {{ $t('plant.stop_collector') }}
                </h2>
                <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: solid/calendar -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    {{ current.created_at }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex lg:mt-0 lg:ml-4">

                <span class="ml-3">
                  <button @click="toggleDashboard" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg v-if="!activateDashboard" class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                    </svg>
                    <svg v-if="activateDashboard" class="-ml-1 -mr-1 h-5 w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </button>
                </span>

                <span v-if="user.is_sys_admin || user.is_admin" class="ml-3">
                  <button @click="openStopEditModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </button>
                </span>

                <span class="ml-3">
                    <a href="#" @click.prevent="$router.go(-1)" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/link -->
                    <svg class="-ml-1 mr-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    {{ $t('general.back') }}
                  </a>
                </span>

              </div>
            </div>

            <StopChart v-if="stopTable.length > 0 && showChart" v-bind:series="series" />
            <StopList v-if="stopTable.length > 0 && showChart" v-bind:stops="stopTable" />

            <div v-if="stopTable.length == 0 && current.stop_table !== null && (user.is_sys_admin || user.is_admin)" class="rounded bg-white w-full ">
              <StopHelper v-bind:endpoints="endpoints" />
            </div>

            <div v-if="stopTable.length == 0 && current.stop_table !== null && (user.is_user)" class="text-center ">
              <EmptyState />
            </div>

            <button @click="openCreateHierarchyStopCollectionModal = true" v-show="current.stop_table === null"  type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
              </svg>
              <span class="mt-2 block text-sm font-medium text-gray-900">
                <p>{{ current.name }}</p>
                <p>{{ $t('plantStops.create_new') }}</p>
              </span>
            </button>

          </div>
        </div>
 
      </div>
    </div>
    <!-- Footer -->
    <Footer />
    <CreateHierarchyStopCollectionModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyStopCollectionModal"  @hide="openCreateHierarchyStopCollectionModal = false" @refresh="getHierarchyChildren(); getStopLogging()" />
    <EditStopModal :current="current" :sensors="dataTable" v-if="openStopEditModal"  @hide="openStopEditModal = false" @refresh="getStopLogging();" />
  </main>
</div>
</template>

<script>
import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import CreateHierarchyStopCollectionModal from '../modals/CreateHierarchyStopCollectionModal.vue'
import EditStopModal from '../modals/EditStopModal.vue';
import StopList from '../subcomponents/StopList.vue'
import StopHelper from '../subcomponents/StopHelper.vue'
import EmptyState from '../subcomponents/EmptyState.vue'
import StopChart from '../charts/StopChart.vue'
import moment from 'moment'


export default {
  name: 'PlantStops',
  components: {
    TopNav,
    Footer,
    CreateHierarchyStopCollectionModal,
    StopList,
    StopHelper,
    StopChart,
    EmptyState,
    EditStopModal
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      endpoints: [],
      selectedDate: moment().format("YYYY-MM-DD"),
      stopTable: {},
      dataTable: {},
      hierarchy: {},
      current: {},
      customer: {},
      showDatabutton: true,
      openCreateHierarchyStopCollectionModal: false,
      openStopEditModal: false,
      showChart: false,
      series: [],
      activateDashboard: false
    }
  },
  mounted(){
    this.checkToken()
    this.getHierarchyChildren()
    this.getStopLogging()
    this.getDatalogging()
  },
  watch: {
    "$route.params.id": {
      handler () {
        this.getStopLogging();
      },
      immediate: true 
    }
  },
  methods: {
    toggleDashboard() {
      this.activateDashboard = !this.activateDashboard;
      this.$API.postData('/stoplogging/dashboard',  { hierarchy_id: this.$route.params.id, sensor_id: this.$route.params.mid, active: this.activateDashboard})
      .then();
    },
    checkToken() {
      this.$API.getData('/auth')
        .then(response => {
          if(response.status != 200) {
            this.$router.push('/')
          }
        });
    },
    getStopLogging() {
      this.$API.getData('/stoplogging',  {id: this.$route.params.id})
      .then(response => {
          this.endpoints = response.endpoints;
          if(response.hierarchy.activateDashboard == 1) {
            this.activateDashboard = true;
          }
          if(response.stopTable != null){
              this.stopTable = response.stopTable;
              this.series = response.series;
              this.showChart = true;

              if(this.stopTable != null){
                this.showDatabutton = false;
              }else{
                this.showDatabutton = true;
              }
          }
      });
    },
    getDatalogging() {
      this.$API.getData('/datalogging',  {id: this.$route.params.id})
      .then(response => {

          if(response.metaTable != null){
              this.dataTable = response.metaTable;
          }
      });
    },
    getHierarchyChildren() {
      this.$API.getData('/hierarchy', {id: this.$route.params.id})
        .then(response => {
          this.hierarchy = response.data.hierarchy;
          this.customer = response.data.customer;
          this.current = response.data.current;
          
          if(this.current === null) {
            this.current = this.customer;
            this.current.is_customer = true;
          } else {
            this.current.is_customer = false;
          }
        });
    },
    diffDate(from, to) {
       
        var a = moment(from)
        var b = moment(to)
        var ss = b.diff(a, 'seconds');

        let parts = [];
        const duration = moment.duration(ss, 'seconds');

        if(!duration || duration.toISOString() === "P0D") return;

        if(duration.years() >= 1) {
            const years = Math.floor(duration.years());
            parts.push(years+" "+(years > 1 ? this.$t('general.years') : this.$t('general.year')));
        }

        if(duration.months() >= 1) {
            const months = Math.floor(duration.months());
            parts.push(months+" "+(months > 1 ? this.$t('general.months') : this.$t('general.month')));
        }

        if(duration.days() >= 1) {
            const days = Math.floor(duration.days());
            parts.push(days+" "+(days > 1 ? this.$t('general.days') : this.$t('general.day')));
        }

        if(duration.hours() >= 1) {
            const hours = Math.floor(duration.hours());
            parts.push(hours+" "+(hours > 1 ? this.$t('general.hours') : this.$t('general.hour')));
        }

        if(duration.minutes() >= 1) {
            const minutes = Math.floor(duration.minutes());
            parts.push(minutes+" "+(minutes > 1 ? this.$t('general.minutes') : this.$t('general.minute')));
        }

        if(duration.seconds() >= 1) {
            const seconds = Math.floor(duration.seconds());
            parts.push(seconds+" "+(seconds > 1 ? this.$t('general.seconds') : this.$t('general.second')));
        }

        return parts.join(", ");
    }
  }
}
</script>