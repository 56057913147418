<template>
      <apexchart height="45" :options="options" :series="options.series"></apexchart>
</template>
<script>

export default {
  name: 'SparklineChart',
  props: ['node'],
  components: {
  },
  data() {
    return {
        options: {
            series: [{
                name: (this.node.friendly_name === null) ? this.node.name : this.node.friendly_name,
                data: this.node.data
            }],
            colors:['#6366f1'],
            fill: {
                colors: ['#6366f1']
            },
            chart: {
                type: 'area',
                width: 100,
                height: '35px',
                sparkline: {
                    enabled: true
                },
                animations: {
                    enabled: false,
                }
            },
            
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    show: false
                },
                marker: {
                    show: false
                }
            }
        }
    }
  },
  mounted() {
  },
  methods: {

    
  },
  
}
</script>
