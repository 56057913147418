<template>
<div>

  <div v-if="servers.length == 0" class="bg-white overflow-hidden shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      
      <div  class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="text-center">
        <svg class="mx-auto h-20 w-20 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">{{$t('settings.no_servers_title')}}</h3>
        <p class="mt-1 text-sm text-gray-500">
          {{$t('settings.no_servers_description')}}
        </p>
        <div class="mt-6">
          <button @click="openServerModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{$t('settings.order_server')}}
          </button>
        </div>
      </div>
  </div>

    </div>
  </div>

  
  <div v-if="servers.length > 0" class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('settings.server_name') }}
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('settings.server_status') }}
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('settings.server_ip') }}
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="server in servers" v-bind:key="server.id">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <svg v-if="server.type == 'LoRa Network Server'" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 h-10 w-10 rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                      </svg>
                      <svg v-if="server.type == 'MQTT Broker'" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 h-10 w-10 rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                      <svg v-if="server.type == 'Database Server'" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 h-10 w-10 rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                      </svg>
                      <svg v-if="server.type == 'General Server'" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 h-10 w-10 rounded-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{server.name}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{server.type}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span :class="server.server_status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full ">
                    {{$t('settings.'+server.creation_status)}}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="server.server_status">{{server.ip_address}}</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a @click.prevent="openServerShell(server)" v-if="server.server_status" href="#" class="text-indigo-600 hover:text-indigo-900">{{$t('settings.connect_server')}}</a>
                </td>
              </tr>

            </tbody>
          </table>

          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button @click="openServerModal = true" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {{$t('settings.order_server')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CreateServerModal v-if="openServerModal"  @hide="openServerModal = false" @refresh="getServers()" />
</div>
</template>

<script>

import CreateServerModal from '../modals/CreateServerModal.vue'

export default {
    name: 'ServerList',
    components: {
      CreateServerModal,
    },
    data() {
        return {
          openServerModal: false,
          servers: [],
        }
    },
    mounted() {
      this.getServers()
    },
    methods: {
      openModal() {
        this.$emit('open');
      },
      openServerShell(server) {
        window.open(`https://shell.lora.systems?hostname=${server.ip_address}&username=${server.username}&password=${btoa(server.password)}`, server.name, 'width=600,height=400')
      },
      getServers() {
        this.$API.getData('/customer/servers', {})
          .then(response => {
            this.servers = response.data;
          });
      }
    }
}
</script>