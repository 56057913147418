<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">


    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="/logo-lg.png" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{$t('login.login_account')}}
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{$t('login.or')}}  
        <router-link
          :to="'/register'"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate" class="font-medium text-indigo-600 hover:text-indigo-500">
            {{$t('login.try_for_free')}}
          </a>
        </router-link>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" action="#" method="POST" @submit.prevent="login">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{$t('login.email')}}
            </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              {{$t('login.password')}}
            </label>
            <div class="mt-1">
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input v-model="remember" id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                {{$t('login.stay_logged_in')}}
              </label>
            </div>

            <div class="text-sm">
              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                {{$t('login.forgot_password')}}
              </a>
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {{$t('login.login')}}
            </button>
          </div>
        </form>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                {{$t('login.or')}} 
              </span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-1 gap-3">
            <div>
              <router-link
                :to="'/register'"
                v-slot="{ href, navigate }"
                custom
              >
                <a :href="href" @click="navigate" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  {{$t('login.register_company')}}
                </a>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Login',
  components: {
      
  },
  data() {
    return {
        token: null,
        email: null,
        password: null,
        remember: null
    }
  },
  beforeMount(){
    this.checkToken()
  },
  methods: {
    checkToken() {
      if(typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null) {
        this.$API.getData('/auth')
        .then(response => {
          if(response.status == 200) {
            this.$router.push('/dashboard')
          }
        });
      }
    },
    login() {
      this.$API.postData('/login', { email: this.email, password: this.password })
        .then(response => {
          if(response.status == 200) {
              localStorage.setItem('token', response.data.token);
              if(typeof response.data.customer !== 'undefined') {
                localStorage.setItem('customer', JSON.stringify(response.data.customer));
              }
              if(typeof response.data.user !== 'undefined') {
                localStorage.setItem('user', JSON.stringify(response.data.user));
              }
              this.$router.push('/dashboard')
          }
        });
    }
  }
}
</script>