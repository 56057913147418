<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav/>


  <main>
    <div v-if="dashboards.length == 0" class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="text-center">
        <svg class="mx-auto h-20 w-20 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <h3 class="mt-2 text-sm font-medium text-gray-900">{{$t('general.empty_dashboard_title')}}</h3>
        <p class="mt-1 text-sm text-gray-500">
          {{$t('general.empty_dashboard_description')}}
        </p>
        <div class="mt-6">
          <router-link
            :to="'/plant'"
            v-slot="{ href, navigate }"
            custom
          >
                    
          <a :href="href" @click="navigate" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/plus -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
            </svg>
            {{$t('general.empty_dashboard_buttom')}}
          </a>
          </router-link>
        </div>
      </div>
    </div>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">
        <div class="bg-white overflow-hidden shadow sm:rounded-lg mb-5" v-for="(dashboard, index) in dashboards" :key="dashboard.id">
          <div class="px-4 py-5 sm:p-6">
            <div class="lg:flex lg:items-center lg:justify-between mb-10">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{dashboard.name}} - {{(typeof dashboard.sensor !== 'undefined') ? dashboard.sensor.friendly_name : "Avvikelser" }}
                </h2>
              </div>

              <!--
                <span class="hidden sm:block ml-3">
                  <button type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="-ml-1 mr-2 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                    </svg>
                    {{ $t('dashboard.share') }}
                  </button>
                </span>
                -->

                <span class="relative z-0 inline-flex shadow-sm rounded-md sm:ml-3">
                  <button @click="moveUp(dashboard, index)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-l-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:outline-none focus:outline-none focus:outline-none">
                    <span class="sr-only">Move up</span>
                    <!-- Heroicon name: solid/chevron-left -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                  </button>
                  <button @click="moveDown(dashboard,index)" type="button" class="-ml-px inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:outline-none focus:outline-none focus:outline-none">
                    <span class="sr-only">Move down</span>
                    <!-- Heroicon name: solid/chevron-right -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </span>

            </div>

            <div class="rounded bg-white w-full ">
              <div v-if="dashboard.stop == 0">
                <div class="flex items-center ">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                      <h2 class="text-2xl font-bold leading-7 text-indigo-700 sm:text-3xl sm:truncate">
                        <span v-if="dashboard.sensor.data.length > 0">{{Math.round(dashboard.sensor.data[dashboard.sensor.data.length-1])}}{{dashboard.sensor.unit}}</span>
                        <span v-if="dashboard.sensor.data.length == 0">--{{dashboard.sensor.unit}}</span>
                        </h2>
                    </div>
                    <div class="min-w-0 flex-1 pl-4">
                      <div>
                        <DashboardChart v-bind:node="dashboard" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="dashboard.stop == 1">
                <div class="flex items-center ">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                      <span v-if="dashboard.stops.stops.length > 0 && dashboard.stops.stops[dashboard.stops.stops.length-1].ongoing" class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                      <span v-if="dashboard.stops.stops.length == 0 || !dashboard.stops.stops[dashboard.stops.stops.length-1].ongoing" class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </div>
                    <div class="min-w-0 flex-1 pl-4">
                      <div>
                        <StopChart v-bind:series="dashboard.stops.series" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <Footer />
  </main>
</div>
</template>

<script>


import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import DashboardChart from '../charts/DashboardChart.vue'
import StopChart from '../charts/StopChart.vue'

export default {
  name: 'HelloWorld',
  components: {
    TopNav,
    Footer,
    DashboardChart,
    StopChart
  },
  data() {
    return {
      dashboards: [],
      datalists: [],
    }
  },
  mounted() {
    let customer = JSON.parse(localStorage.getItem('customer'));
    if(!customer.has_db) {
      this.$router.push('/settings')
    }
    this.getDashboards()
  },
  methods: {
    move(input, from, to) {
      let numberOfDeletedElm = 1;

      const elm = input.splice(from, numberOfDeletedElm)[0];

      numberOfDeletedElm = 0;

      input.splice(to, numberOfDeletedElm, elm);
      return input;
    },
    moveUp(dashboard, index) {
      if(index != 0) {
        this.dashboards = this.move(this.dashboards, index, (index-1));
        this.$API.postData('/dashboards/sort', { direction: "up", id: dashboard.id })
            .then(response => {
                if(response.status == 200) {
                  console.log(response.data);
                    //alert("OK");
                }
            });
      }
    },
    moveDown(dashboard, index) {
      if(index != this.dashboards.length-1) {
        this.dashboards = this.move(this.dashboards, index, (index+1));
        this.$API.postData('/dashboards/sort', { direction: "down", id: dashboard.id })
            .then(response => {
                if(response.status == 200) {
                  console.log(response.data);
                    //alert("OK");
                }
            });
      }
    },
    getDashboards() {
      this.$API.getData('/dashboards',  {  })
      .then(response => {
        this.dashboards = response.data;
        console.log(response.data);
      });
    }
  }
  
}
</script>