<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav/>

  <main>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">

        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">

            <div class="lg:flex lg:items-center lg:justify-between mb-10">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{ current.is_customer ? 'Anläggning' : current.name }} - {{sensor.friendly_name == null ? sensor.name : sensor.friendly_name}}
                </h2>
                <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: solid/calendar -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    {{ current.created_at }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex lg:mt-0 lg:ml-4">

                <span class="ml-3">
                  <button @click="toggleDashboard" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg v-if="!activateDashboard" class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                    </svg>
                    <svg v-if="activateDashboard" class="-ml-1 -mr-1 h-5 w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </button>
                </span>

                <span class="ml-3">
                  <button @click="toggleNotifications" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg v-if="!activateNotifications" class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                    <svg v-if="activateNotifications" class="-ml-1 -mr-1 h-5 w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                    </svg>
                  </button>
                </span>

                <span v-if="user.is_sys_admin || user.is_admin" class="ml-3">
                  <button @click="openSensorEditModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </button>
                </span>

                <span v-if="!current.is_customer && (user.is_sys_admin || user.is_admin)" class="ml-3">
                  <button @click="openShareModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                  </button>
                </span>



                <span class="ml-3">
                    <a href="#" @click.prevent="$router.go(-1)" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/link -->
                    <svg class="-ml-1 mr-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    {{ $t('general.back') }}
                  </a>
                </span>
              </div>
            </div>

                <LineChart v-show="loggerData" ref="chart" />
                
                <div v-show="!loggerData"  class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 16 36 36 M36 16 16 36" />
                  </svg>
                  <span class="mt-2 block text-sm font-medium text-gray-900">
                    <p>{{ current.name }}</p>
                     <p>{{ $t('dataChart.no_data') }}</p>
                  </span>
                </div>

          </div>
        </div>
        <DataExports class="mt-5" v-show="loggerData" :dataExports="dataExports" />
      </div>
    </div>
    <!-- Footer -->
    <Footer />
    <CreateHierarchyModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyModal"  @hide="openCreateHierarchyModal = false" @refresh="getHierarchyChildren(); getDataLogging()" />
    <CreateHierarchyDataCollectionModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyDataCollectionModal"  @hide="openCreateHierarchyDataCollectionModal = false" @refresh="getHierarchyChildren(); getDataLogging()" />
    <EditSensorModal :sensor="sensor" v-if="openSensorEditModal"  @hide="openSensorEditModal = false" @refresh="getSensor();" />
    <CreatePublicViewModal :hierarchy_id="current.id" :meta_id="sensor.id" v-if="openShareModal"  @hide="openShareModal = false" @refresh="showPublicUrl" />
    <PublicViewUrlModal :url="publicViewUrl"  v-if="openPublicViewUrlModal"  @hide="openPublicViewUrlModal = false"  />
  
  </main>
</div>
</template>

<script>
import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import LineChart from '../charts/LineChart.vue'
import CreateHierarchyModal from '../modals/CreateHierarchyModal.vue'
import CreateHierarchyDataCollectionModal from '../modals/CreateHierarchyDataCollectionModal.vue'
import EditSensorModal from '../modals/EditSensorModal.vue'
import DataExports from '../subcomponents/DataExports.vue'
import CreatePublicViewModal from '../modals/CreatePublicViewModal.vue'
import PublicViewUrlModal from '../modals/PublicViewUrlModal.vue'


export default {
  name: 'PlantData',
  components: {
    TopNav,
    Footer,
    LineChart,
    CreateHierarchyModal,
    CreateHierarchyDataCollectionModal,
    EditSensorModal,
    DataExports,
    CreatePublicViewModal,
    PublicViewUrlModal,
  },
  data() {
    return {
      hierarchy: {},
      sensor: {},
      current: {},
      customer: {},
      publicViewUrl: null,
      openPublicViewUrlModal: false,
      user: JSON.parse(localStorage.getItem('user')),
      showDatabutton: true,
      openCreateHierarchyModal: false,
      openCreateHierarchyDataCollectionModal: false,
      openSensorEditModal: false,
      dataLoggInterval: 0,
      activateNotifications: false,
      activateDashboard: false,
      loggerData: true,
      dataExports: [],
      dates: {
        date1: null,
        date2: null
      },
      openShareModal: false,
    }
  },
  mounted(){
    this.checkToken()
    this.getHierarchyChildren()
    this.getDataLogging()
    this.getSensor()
    this.startLineChart()
  },
  watch: {
    "$route.params.id": {
      handler () {
        //this.getDataLogging();
      },
      immediate: true 
    }
  },
  methods: {
    checkToken() {
      this.$API.getData('/auth')
        .then(response => {
          if(response.status != 200) {
            this.$router.push('/')
          }
        });
    },
    toggleNotifications() {
      this.activateNotifications = !this.activateNotifications;
      this.$API.postData('/datalogging/sensor/notifications',  { hierarchy_id: this.$route.params.id, sensor_id: this.$route.params.mid, active: this.activateNotifications})
      .then(response => {
        this.sensor = response.data.sensor;
      });
    },
    toggleDashboard() {
      this.activateDashboard = !this.activateDashboard;
      this.$API.postData('/datalogging/sensor/dashboard',  { hierarchy_id: this.$route.params.id, sensor_id: this.$route.params.mid, active: this.activateDashboard})
      .then(response => {
        this.sensor = response.data.sensor;
      });
    },
    datesFunc(date1, date2){
      
      clearInterval(this.dataLoggInterval);
      this.dates.date1 = Date.parse(date1) / 1000;
      this.dates.date2 = Date.parse(date2) / 1000;
      this.startLineChart();
      this.getDataLogging();
      
    },
    startLineChart() {
     this.dataLoggInterval = setInterval(() => {
        this.getDataLogging();
      }, 60000);
    },
    getSensor() {
      this.$API.getData('/datalogging/sensor',  { id: this.$route.params.id, mid: this.$route.params.mid, })
      .then(response => {
        if(response.status == 200) {
          this.sensor = response.data.sensor;
          this.dataExports = response.data.sensor.exports;
          if(this.sensor.activateNotifications == 1) {
            this.activateNotifications = true;
          }
          if(this.sensor.activateDashboard == 1) {
            this.activateDashboard = true;
          }
        } else {
          this.$router.push('/plant/'+this.$route.params.id);
        }

      });
    },
    getDataLogging() {
        this.$API.getData('/datalogging/getChartData',  {id: this.$route.params.id, mid: this.$route.params.mid, date1:this.dates.date1, date2:this.dates.date2 })
          .then(response => {
            var data = response.data;
            if(data.length > 0){
              this.loggerData = true;
              var dtname = response.metaName;
              if(typeof this.$refs.chart != "undefined") {
                  this.$refs.chart.dataToChart(data,dtname);
              }
            } else {
              console.log("empty");
              clearInterval(this.dataLoggInterval)
              this.loggerData = false;
            }
      });
    },
    getHierarchyChildren() {
      this.$API.getData('/hierarchy', {id: this.$route.params.id})
        .then(response => {
          this.hierarchy = response.data.hierarchy;
          this.customer = response.data.customer;
          this.current = response.data.current;
         
          //this.current.is_customer = false;
          if(this.current === null) {
            this.current = this.customer;
            this.current.is_customer = true;
          } else {
            this.current.is_customer = false;
          }

        });
    },
    showPublicUrl(url) {
      this.publicViewUrl = url;
      this.openPublicViewUrlModal = true;
    }
  }
}
</script>