var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"bg-white shadow"},[_c('div',{staticClass:"max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"},[_c('div',{staticClass:"relative flex justify-between h-16"},[_c('div',{staticClass:"absolute inset-y-0 left-0 flex items-center sm:hidden"},[_c('button',{staticClass:"inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500",attrs:{"type":"button","aria-controls":"mobile-menu","aria-expanded":"false"},on:{"click":function($event){_vm.showMobileMenu = !_vm.showMobileMenu}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Open main menu")]),(!_vm.showMobileMenu)?_c('svg',{staticClass:"block h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 6h16M4 12h16M4 18h16"}})]):_vm._e(),(_vm.showMobileMenu)?_c('svg',{staticClass:"block h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})]):_vm._e()])]),_c('div',{staticClass:"flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"},[_c('div',{staticClass:"flex-shrink-0 flex items-center"},[(_vm.logo !== null)?_c('img',{staticClass:"block lg:hidden h-8 w-auto",attrs:{"src":_vm.logo,"alt":"Workflow"}}):_vm._e(),(_vm.logo !== null)?_c('img',{staticClass:"hidden lg:block h-8 w-auto",attrs:{"src":_vm.logo,"alt":"Workflow"}}):_vm._e()]),_c('div',{staticClass:"hidden sm:ml-6 sm:flex sm:space-x-8"},[(_vm.has_db)?_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.dashboard')))])]}}],null,false,2467725749)}):_vm._e(),(_vm.has_db)?_c('router-link',{attrs:{"to":"/plant","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.plant')))])]}}],null,false,2753101686)}):_vm._e(),(_vm.has_db && (_vm.user.is_sys_admin))?_c('router-link',{attrs:{"to":"/settings","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.settings')))])]}}],null,false,4066731284)}):_vm._e(),(!_vm.has_db && (_vm.user.is_sys_admin))?_c('router-link',{attrs:{"to":"/settings","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'border-red-500 text-red-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-red-500 hover:border-red-300 hover:text-red-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.settings')))])]}}],null,false,2333045925)}):_vm._e()],1)]),_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"},[_c('span',{staticClass:"inline-block relative"},[_c('button',{staticClass:"bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",on:{"click":function($event){_vm.showEventsMenu = true}}},[_c('span',{staticClass:"sr-only"},[_vm._v("View notifications")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"}})])])]),_c('div',{staticClass:"ml-3 relative"},[_c('div',[_c('router-link',{attrs:{"to":"/profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"sr-only"},[_vm._v("Open user menu")]),(_vm.user.profile_picture !== null)?_c('img',{staticClass:"h-8 w-8 rounded-full",attrs:{"src":_vm.$API_URL+'/uploads/'+_vm.user.profile_picture,"alt":""}}):_vm._e(),(_vm.user.profile_picture === null)?_c('svg',{staticClass:"h-8 w-8 rounded-full text-gray-300",attrs:{"fill":"currentColor","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"}})]):_vm._e()])]}}])})],1)])])])]),(_vm.showEventsMenu)?_c('Events',{attrs:{"events":_vm.events},on:{"hide":function($event){_vm.showEventsMenu = false}}}):_vm._e(),(_vm.showMobileMenu)?_c('div',{staticClass:"sm:hidden",attrs:{"id":"mobile-menu"}},[_c('div',{staticClass:"pt-2 pb-3 space-y-1"},[_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.dashboard')))])]}}],null,false,1580400679)}),_c('router-link',{attrs:{"to":"/plant","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.plant')))])]}}],null,false,1738584612)}),(_vm.user.is_admin || _vm.user.is_sys_admin)?_c('router-link',{attrs:{"to":"/settings","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.settings')))])]}}],null,false,2091887302)}):_vm._e()],1),_c('div',{staticClass:"pt-4 pb-3 border-t border-gray-200"},[_c('div',{staticClass:"flex items-center px-4"},[_c('div',{staticClass:"flex-shrink-0"},[(_vm.user.profile_picture !== null)?_c('img',{staticClass:"h-10 w-10 rounded-full",attrs:{"src":_vm.$API_URL+'/uploads/'+_vm.user.profile_picture,"alt":""}}):_vm._e(),(_vm.user.profile_picture === null)?_c('svg',{staticClass:"h-10 w-10 rounded-full text-gray-300",attrs:{"fill":"currentColor","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"}})]):_vm._e()]),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"text-base font-medium text-gray-800"},[_vm._v(_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname))]),_c('div',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(_vm._s(_vm.user.email))])])]),_c('div',{staticClass:"mt-3 space-y-1"},[_c('router-link',{attrs:{"to":"/profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{class:[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav.profile')))])]}}],null,false,2772964968)}),_c('a',{staticClass:"block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('nav.logout')))])],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }