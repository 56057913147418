<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav/>


  <main>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">

        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">

            <div class="lg:flex lg:items-center lg:justify-between mb-5 pb-5 border-b border-gray-200">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{ current.is_customer ? 'Anläggning' : current.name }}  - {{ $t('plant.data_collector') }}
                </h2>
                <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: solid/calendar -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    {{ current.created_at }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex lg:mt-0 lg:ml-4">

                <span>
                    <a href="#" @click.prevent="$router.go(-1)" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/link -->
                    <svg class="-ml-1 mr-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    {{ $t('general.back') }}
                  </a>
                </span>

              </div>
            </div>

            <div v-if="dataTable.length > 0" class="rounded bg-white w-full ">
              <div class="bg-white shadow overflow-hidden sm:rounded-md">
                <DataList v-bind:nodes="dataTable" v-bind:current="current" />
              </div>  
              <div class="max-w-xl mx-auto ">
                <div class="text-center mt-5">
                  <label for="name" class="ml-px pl-4 block text-sm font-medium text-gray-700">Snabbintegration</label>
                  <div class="mt-1">
                    <input readonly :value="$API_URL + '/collect/data/'+endpoints.root+'/'+endpoints.node" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="API Endpoint">
                  </div>
                </div>
              </div>
            </div>
            
            <div v-if="dataTable.length == 0 && current.data_table !== null" class="rounded bg-white w-full ">
              <DataHelper v-bind:endpoints="endpoints" />
            </div>

            <button @click="openCreateHierarchyDataCollectionModal = true" v-show="current.data_table === null"  type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
              </svg>
              <span class="mt-2 block text-sm font-medium text-gray-900">
                <p>{{ current.name }}</p>
                <p>{{ $t('plantData.create_new') }}</p>
              </span>
            </button>

          </div>
        </div>
 
      </div>
    </div>
    <!-- Footer -->
    <Footer />
    <CreateHierarchyDataCollectionModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyDataCollectionModal"  @hide="openCreateHierarchyDataCollectionModal = false" @refresh="getHierarchyChildren(); getDataLogging()" />
  </main>
</div>
</template>

<script>
import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import CreateHierarchyDataCollectionModal from '../modals/CreateHierarchyDataCollectionModal.vue'
import DataList from '../subcomponents/DataList.vue'
import DataHelper from '../subcomponents/DataHelper.vue'




export default {
  name: 'PlantData',
  components: {
    TopNav,
    Footer,
    CreateHierarchyDataCollectionModal,
    DataList,
    DataHelper
  },
  data() {
    return {
      loggerItems: {},
      hierarchy: {},
      current: {},
      customer: {},
      dataTable: {},
      endpoints: {},
      showDatabutton: true,
      openCreateHierarchyDataCollectionModal: false
    }
  },
  mounted(){
    this.checkToken()
    this.getHierarchyChildren()
    this.getDataLogging()   
  },
  watch: {
    "$route.params.id": {
      handler () {
        this.getDataLogging();
      },
      immediate: true 
    }
  },
  methods: {
    checkToken() {
      this.$API.getData('/auth')
        .then(response => {
          if(response.status != 200) {
            this.$router.push('/')
          }
        });
    },
    getDataLogging() {
      this.$API.getData('/datalogging',  {id: this.$route.params.id})
      .then(response => {

          if(response.metaTable != null){
              this.dataTable = response.metaTable;
              this.loggerItems = response.metaTable;
              
              if(this.dataTable != null){
                this.showDatabutton = false;
                this.endpoints = response.endpoints;
              }else{
                this.showDatabutton = true;
              }
          }
      });
    },
    getHierarchyChildren() {
      this.$API.getData('/hierarchy', {id: this.$route.params.id})
        .then(response => {
          this.hierarchy = response.data.hierarchy;
          this.customer = response.data.customer;
          this.current = response.data.current;
          
          if(this.current === null) {
            this.current = this.customer;
            this.current.is_customer = true;
          } else {
            this.current.is_customer = false;
          }
        });
    }
  }
}
</script>