import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import './index.css'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

import PublicView from './components/pages/PublicView.vue';
import Login from './components/pages/Login.vue';
import Register from './components/pages/Register.vue';
import Activate from './components/pages/Activate.vue';
import Dashboard from './components/pages/Dashboard.vue';
import Plant from './components/pages/Plant.vue';
import PlantData from './components/pages/PlantData.vue';
import PlantStops from './components/pages/PlantStops.vue';
import Settings from './components/pages/Settings.vue';
import DataChart from './components/pages/DataChart.vue';
import Profile from './components/pages/Profile.vue';

import API from './services/api.js';

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { name: 'Login', path: '/', component: Login },
        { name: 'Register', path: '/register', component: Register },
        { name: 'Activate', path: '/activate/:token', component: Activate },
        { name: 'Dashboard', path: '/dashboard', component: Dashboard },
        { name: 'Plant', path: '/plant', component: Plant },
        { name: 'PlantData', path: '/plant/:id/data', component: PlantData },
        { name: 'PlantStops', path: '/plant/:id/stops', component: PlantStops },
        { name: 'DataChart', path: '/plant/:id/data/:mid', component: DataChart },
        { name: 'Hierarchy', path: '/plant/:id', component: Plant },
        { name: 'Inställningar', path: '/settings', component: Settings },
        { name: 'Profil', path: '/profile', component: Profile },
        { name: 'Publik', path: '/v/:key', component: PublicView },

    ]
});

router.beforeEach((to, from, next) => {
    let hasAuth = (typeof localStorage.getItem('token') === 'undefined' || localStorage.getItem('token') === null) ? false : true;
    if ((to.name !== 'Activate' && to.name !== 'Login' && to.name !== 'Register' && to.name != 'Publik') && !hasAuth) {
        next({ name: 'Login' })
    } else {
        next()
    }
})


const messages = {
    "sv": {
        "login": {
            "login_account": "Logga in på ditt konto",
            "or": "eller",
            "try_for_free": "testa gratis i 14 dagar",
            "register_company": "Registrera ditt företag",
            "email": "E-post adress",
            "password": "Lösenord",
            "stay_logged_in": "Förbli inloggad",
            "forgot_password": "Glömt ditt lösenord?",
            "login": "Logga in",
            "activate_account": "Aktivera ditt konto"
        },
        "register": {
            "register_account": "Öppna nytt konto",
            "email": "Personlig e-post",
            "company_settings": "Grundinställningar",
            "company_settings_info": "Ange ert företagsnamn och din personliga e-post adress för inloggning.",
            "company_name": "Företagsnamn",
            "register": "Registrera",
            "captcha": "Captchakod",
            "captcha_info": "Ange koden som står ovan (4 siffror)"
        },
        "logs": {
            "captcha_error": "Säkerhetskoden/captchan är felaktigt inmatad.",
            "user_already_exists": "En användare med denna e-post är redan registerad.",
            "company_already_exists": "Ett företag med detta namn är redan registrerat.",
            "show_all_logs": "Visa logghistorik",
            "request_success": "Anrop lyckades!",
            "request_failed": "Anrop misslyckades!",
            "new_data_collector_created": "En ny datainsamlare har skapats",
            "new_stop_collector_created": "En ny avvikelseinsamlare har skapats",
            "new_hierarchy_node_created": "En ny anläggningsdel har skapats",
            "hierarchy_node_updated": "En anläggningsdel har ändrats",
            "hierarchy_node_deleted": "En anläggningsdel har tagits bort",
            "new_sensor_created": "En ny sensorsignal har samlats in",
            "could_not_create_data_collector": "Kunde inte skapa datainsamlare",
            "could_not_create_stop_collector": "Kunde inte skapa avvikelseinsamlare",
            "information_updated": "Informationen har uppdaterats",
            "could_not_update_information": "Informationen kunde inte uppdateras",
            "unknown_error": "Ett okänt fel har uppstått",
            "new_node_created": "En ny nod har skapats",
            "database_connection_successful": "Databaskoppling lyckades",
            "database_connection_unsuccessful": "Databaskopplingen misslyckades",
            "user_created_successfully": "Användaren lades till utan bekymmer",
            "could_not_create_user": "Användaren kunde inte läggas till",
        },
        "nav": {
            "dashboard": "Dashboard",
            "plant": "Anläggning",
            "data": "Data",
            "stops": "Avvikelser",
            "settings": "Inställningar",
            "profile": "Profilinställningar",
            "logout": "Logga ut",
        },
        "dashboard": {
            "showmap": "Visa karta",
            "change": "Ändra",
            "share": "Dela",
            "export": "Exportera"
        },
        "plant": {
            "data_collector": "Datainsamling",
            "stop_collector": "Avvikelseinsamling"
        },
        "plantData": {
            "create_new": "Påbörja datainsamling här",
        },
        "plantStops": {
            "create_new": "Påbörja avvikelsemätning här",
        },
        "data": {

        },
        "dataChart": {
            "no_data": "Ingen data hittades",
        },
        "stops": {

        },
        "settings": {
            "company_settings": "Företagsinställningar",
            "company_settings_info": "Ange era företagsinställningar, logotyper (Syns högst uppe till vänster), vi använder detta vid kontaktbehov.",
            "company_name": "Företagsnamn",
            "company_org_number": "Org. nummer",
            "company_country": "Land",
            "company_address": "Adress",
            "company_zip": "Postnummer",
            "company_city": "Postort",
            "company_logo": "Företagslogotyp",
            "company_logo_change": "Byt logotyp",
            "company_logo_upload_a_file": "Ladda upp en fil",
            "company_logo_upload_drag_drop": " eller drag och släpp",
            "company_logo_restrictions": "PNG, JPG, GIF upp till 1MB",
            "company_phone": "Telefonnummer",
            "company_email": "E-post adress",
            "invoice_settings": "Fakturainställningar",
            "invoice_settings_info": "Ange era fakturainställningar, vid leverans av faktura via vanlig post 🤮 tillkommer en administrationsavgift på 60kr/faktura.",
            "invoice_country": "Land",
            "invoice_address": "Adress",
            "invoice_zip": "Postnummer",
            "invoice_city": "Postort",
            "invoice_email": "E-post adress",
            "invoice_type": "Leveranssätt",
            "invoice_type_email": "Faktura via e-post",
            "invoice_type_email_info": "En faktura kommer att skickas månadsvis till angiven e-post.",
            "invoice_type_mail": "Faktura via post (+60kr/faktura)",
            "invoice_type_mail_info": "Ett brev skickas månadsvis till angiven adress.",
            "database_settings": "Databasinställningar",
            "database_settings_info": "Self hosting is the shit 😎, vill ni fullt ut styra över er data så ange en databaskoppling (Se till att användaren har möjlighet att skapa/uppdatera/ta bort tabeller)",
            "database_settings_warning": 'Vänligen notera att vid byte så migreras INGEN INSAMLAD DATA utan endast inställningar för användare/anläggningar förflyttas!',
            "database_host": "Hostname / IP adress",
            "database_port": "Portnummer",
            "database_name": "Databasnamn",
            "database_username": "Användarnamn",
            "database_password": "Lösenord",
            "database_self_hosted": "Egen databas",
            "database_self_hosted_info": "Era servrar | Era serverkostnader | Era begränsningar",
            "server_settings": "Serverinställningar",
            "server_settings_info": "Här listas de servrar som ni har, vänligen notera att det kan ta några dagar från det att en server är beställd tills den är levererad. Inloggningsuppgifter mailas ut när servern blir tillgänglig.",
            "server_name": "Namn",
            "server_status": "Serverstatus",
            "server_ip": "IP-adress",
            "no_servers_title": "Behöver ni en server?",
            "no_servers_description": "Vi kan hjälpa er att komma igång",
            "order_server": "Beställ server",
            "lora_network_server": "LoRa Nätverksserver",
            "mqtt_broker": "MQTT broker",
            "database_server": "Databasserver",
            "general_server": "Generell server",
            "connect_server": "Anslut",
            "server_status_ordered": "Beställd",
            "server_status_confirmed": "Bekräftad",
            "server_status_created": "Skapad",
            "server_status_ready": "Redo",
        },
        "profile": {
            "your_profile": "Din profilinformation",
            "your_profile_info": "Ange dina uppgifter / kontaktuppgifter för att förbättra upplevelsen",
            "email": "E-post adress",
            "firstname": "Förnamn",
            "lastname": "Efternamn",
            "phone": "Telefonnummer (SMS-mottagare)",
            "password": "Lösenord",
            "password_repeat": "Repetera lösenord",
            "profile_picture": "Profilbild",
            "change_profile_picture": "Byt profilbild",
            "notifications": "Notifikationer",
            "notification_info": "Om du slår på notifikationerna så tillåter du att vi skickar ut SMS eller e-post till dina angivna kontaktuppgifter, du kan när som helst ändra dessa inställningar.",
            "by_email": "Genom e-post",
            "general_notifications": "Generell information",
            "general_notifications_info": "Bli notifierad om uppdateringar eller annat som rör din organisation.",
            "warning_notifications": "Varningar",
            "warning_notifications_info": "Bli notifierad om angivna gränsvärden över- eller underskrids.",
            "offer_notifications": "Erbjudanden",
            "offer_notifications_info": "Bli notifierad om systemuppdateringar, erbjudanden eller andra nyheter från oss.",
            "by_sms": "SMS-notifieringar",
            "by_sms_info": "Notifieringar skickas även ut genom SMS direkt till din mobil",
            "everything": "Alla ovanstående",
            "same_as_email": "Samma som e-post",
            "no_sms": "Inga SMS-utskick"
        },
        "modals": {
            "create_hierarchy": "Skapa ny nod",
            "create_hierarchy_description": "Skapa en ny organisation, anläggning, maskin eller maskindel, därefter kan du enkelt börja samla in data på tillhörande objekt!",
            "edit_hierarchy": "Redigera nod",
            "edit_hierarchy_description": "Ändra på den valda noden, genom att ta bort noden tas alla underliggande noder samt insamlad data bort!",
            "remove": "Ta bort",
            "confirm_removal": "Bekräfta borttagning",
            "remove_description": "Genom att bocka i rutan kommer denna nod, alla underliggande noder samt all kopplad data att tas bort.",
            "remove_sensor_description": "Genom att bocka i rutan kommer sensor samt all kopplad data att tas bort.",
            "create_data_hierarchy": "Skapa ny datainsamling",
            "create_data_hierarchy_description": "Är du säker på att du vill skapa en ny datainsamling",
            "create_stop_hierarchy": "Skapa ny avvikelseinsamling",
            "create_stop_hierarchy_description": "Är du säker på att du vill skapa en ny avvikelseinsamling",
            "create_hierarchy_user": "Lägg till användare",
            "create_hierarchy_user_description": "Koppla en användare till denna noden och välj deras behörighetsnivå.OBS! Står du längst bak i ledet får användaren per automatik tillgång till hela strukturen.",
            "edit_sensor": "Redigera sensor",
            "edit_sensor_description": "Byt namn och välj storhet på signalen. Här kan du även sätta gränsvärden för att få larm vid över/underskridning.",
            "edit_stop_collector": "Redigera avvikelseinsamling",
            "edit_stop_collector_description": "Du kan enkelt mäta avvikelser baserat på redan existerande signaler genom att välja i listan.",
            "order_server": "Beställ en server",
            "order_server_description": "Vi skapar och konfigurerar en server som är kompatibel med LoRaSYS tjänster, vi använder alltid miljövänlig hosting!",
            "server_type": "Servertyp",
            "create_public_view": "Skapa publik åtkomst",
            "create_public_view_description": "Genom att fortsätta så skapar ni en delbar länk där personen som har tillgång till länken automatiskt får tillgång till den data som visas utan att behöva vara inloggad.",
            "access_period": "Åtkomstperiod",
            "access_url": "Åtkomstadress",
            "1h": "1 timme",
            "2h": "2 timmar",
            "4h": "4 timmar",
            "8h": "8 timmar",
            "24h": "24 timmar",
            "1w": "1 vecka",
            "2w": "2 veckor",
            "1m": "1 månad",
            "2m": "2 månader",
            "3m": "3 månader",
            "6m": "6 månader",
            "1y": "1 år",
            "infinite": "Ej tidsbegränsat"
        },
        "buttons": {
            "login": "Logga in,",
            "logout": "Logga ut",
            "activate": "Aktivera",
            "save": "Spara",
            "cancel": "Avbryt",
            "remove": "Ta bort",
            "create": "Skapa",
            "test_db_connection": "Testa anslutning",
            "change_db_connection": "Byt anslutning",
            "download": "Ladda ned",
            "update": "Uppdatera",
            "change": "Ändra",
            "close": "Stäng"
        },
        "general": {
            "system_administrator": "Systemadministratör",
            "administrator": "Administratör",
            "user": "Användare",
            "privileges": "Behörighetsnivå",
            "email": "E-post adress",
            "name": "Namn",
            "type_of_node": "Typ av nod",
            "node_type": "Benämning",
            "industrial": "Industriellt",
            "organisation": "Organisation",
            "plant": "Anläggning",
            "production_line": "Produktionslinje",
            "machine": "Maskin",
            "machine_part": "Maskindel",
            "realestate": "Fastigheter",
            "country": "Land",
            "county": "Län",
            "city": "Stad",
            "area": "Område",
            "address": "Adress",
            "property": "Fastighet",
            "apartment": "Lägenhet",
            "from": "Från",
            "to": "Till",
            "from_datetime": "Från datum/tid",
            "to_datetime": "Till datum/tid",
            "back": "Tillbaka",
            "year": "år",
            "years": "år",
            "month": "månad",
            "months": "månader",
            "day": "dag",
            "days": "dagar",
            "hour": "timme",
            "hours": "timmar",
            "minute": "minut",
            "minutes": "minuter",
            "second": "sekund",
            "seconds": "sekunder",
            "unit": "Storhet",
            "manual_stop_collection": "Manuell avvikelseinsamling",
            "comparision_sensor": "Sensorsignal",
            "comparision_operator": "Jämförelseoperator",
            "no_comparision": "Ingen jämförelse",
            "gt_than": "Större än",
            "gt_equal_than": "Större eller lika med",
            "lt_than": "Mindre än",
            "lt_equal_than": "Mindre eller lika med",
            "is_equal": "Är lika med",
            "is_not_equal": "Skiljt från",
            "comparision_value": "Jämförelsevärde",
            "welcome_title": "Välkommen till ditt nya system!",
            "welcome_description": "Din databasanslutning verkar fungera som den ska, så nu är det bara att börja!",
            "welcome_button": "Börja nu!",
            "empty_dashboard_title": "Här är ditt dashboard!",
            "empty_dashboard_description": "Just nu verkar det som att det är tomt, men du kan enkelt lägga till dina mätpunkter här",
            "empty_dashboard_buttom": "Börja här!",

        },
        "dataModal": {
            "friendly_name": "Namn",
            "unit": "Enhet",
        }
    },
}

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'sv', // set locale
    messages, // set locale messages
})

Vue.prototype.$API = API;
Vue.prototype.$API_URL = process.env.VUE_APP_API_URL;
Vue.prototype.$APP_URL = process.env.VUE_APP_URL;

new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')