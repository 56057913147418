<template>
  <div class="max-w-xl mx-auto ">
                <div class="text-center">
                  <p class="text-sm font-semibold text-indigo-600 uppercase tracking-wide">Datainsamling</p>
                  <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Börja samla in data</h1>
                  <p class="mt-2 text-lg text-gray-500">Här hittar du lite resurser som kan hjälpa dig på vägen!</p>
                  <div class="mt-5">
                  <label for="name" class="ml-px pl-4 block text-sm font-medium text-gray-700">Snabbintegration</label>
                  <div class="mt-1">
                    <input readonly :value="$API_URL+'/collect/data/'+endpoints.root+'/'+endpoints.node" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="API Endpoint">
                  </div>
                  </div>
                </div>
                <div class="mt-12">
                  <h2 class="text-sm font-semibold text-gray-500 tracking-wide uppercase">Populär läsning</h2>
                  <ul role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/book-open -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Dokumentation
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Lär dig grunderna i de olika metoderna för insamling</p>
                      </div>
                      <div class="flex-shrink-0 self-center">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/view-list -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              API referenser
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">En komplett lista på alla anrop som görs för att samla in eller hämta ut data</p>
                      </div>
                      <div class="flex-shrink-0 self-center">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/bookmark-alt -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Snabbstartsguider
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Steg för steg, från hårdvara till analys</p>
                      </div>
                      <div class="flex-shrink-0 self-center">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/rss -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Bloggen
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Hitta de senaste nyheterna</p>
                      </div>
                      <div class="flex-shrink-0 self-center">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
</template>

<script>

export default {
    name: 'DataHelper',
    props: ['endpoints'],
    components: {
    }
}
</script>