<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav :key="reloader"/>


  <main>
    <div class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">

        

        <div class="sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1 ">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('settings.company_settings') }}</h3>
                <p class="mt-1 text-sm text-gray-600">
                  {{ $t('settings.company_settings_info') }}
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form @submit.prevent="saveCustomer" action="#" method="POST">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-4">
                        <label for="company-name" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_name') }}</label>
                        <input v-model="customer.company_name" type="text" name="company-name" id="company-name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>
                      <div class="col-span-2">
                        <label for="company-org_number" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_org_number') }}</label>
                        <input v-model="customer.company_org_number" type="text" name="company-org_number" id="company-org_number"  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6">
                        <label for="company-address" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_address') }}</label>
                        <input v-model="customer.company_address" type="text" name="company-address"  id="company-address" autocomplete="street-address" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="company-country" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_country') }}</label>
                        <input v-model="customer.company_country" type="text" name="company-country" id="company-country" autocomplete="country" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label for="company-city" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_city') }}</label>
                        <input v-model="customer.company_city" type="text" name="company-city" id="company-city" autocomplete="locality" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="company-zip" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_zip') }}</label>
                        <input v-model="customer.company_zip" type="text" name="company-zip" id="company-zip" autocomplete="postal-code" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-4">
                        <label for="company-email" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_email') }}</label>
                        <input v-model="customer.company_email" type="text" name="company-email" id="company-email" autocomplete="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>
                      <div class="col-span-2">
                        <label for="company-phone" class="block text-sm font-medium text-gray-700">{{ $t('settings.company_phone') }}</label>
                        <input v-model="customer.company_phone" type="text" name="company-phone" id="company-phone" autocomplete="tel" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>
                    </div>

                    <div class="py-5">
                      <div class="border-t border-gray-200"></div>
                    </div>

                    <div class="sm:col-span-6">
                      <label for="photo" class="block text-sm font-medium text-gray-700">
                        {{ $t('settings.company_logo')}}
                      </label>
                      <div class="mt-1 flex items-center">
                        <span class="overflow-hidden bg-white">
                          <img v-if="company_logo_preview" :src="company_logo_preview" class="max-w-xs text-gray-300" />
                          <img v-if="customer.company_logo !== null && !company_logo_preview" :src="$API_URL+'/uploads/'+customer.company_logo" class="max-w-xs text-gray-300" >
                          <img v-if="customer.company_logo === null && !company_logo_preview" :src="'/logo-lg.png'" class="h-full w-full text-gray-300">
                        </span>
                        
                      </div>
                      <p class="text-sm font-medium text-gray-500">
                          {{ $t('settings.company_logo_restrictions') }}
                        </p>
                      <div class="mt-5">
                        <label for="file-upload">
                          <span class="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 cursor-pointer focus:ring-offset-2 focus:ring-indigo-500">{{ $t('settings.company_logo_change')}}</span>
                          <input id="file-upload" name="file-upload" type="file" class="sr-only" ref="company_logo_file" v-on:change="handleFileUpload()">
                        </label>
                        <button @click="saveCompanyLogo()" v-if="company_logo_preview" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          {{ $t('buttons.save') }}
                        </button>
                      </div>
                    </div>

                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{ $t('buttons.save') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('settings.invoice_settings') }}</h3>
                <p class="mt-1 text-sm text-gray-600">
                  {{ $t('settings.invoice_settings_info') }}
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form @submit.prevent="saveInvoice" action="#" method="POST">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
 
                      <div class="col-span-6">
                        <label for="invoice-address" class="block text-sm font-medium text-gray-700">{{ $t('settings.invoice_address') }}</label>
                        <input v-model="invoice.invoice_address" type="text" name="invoice-address"  id="invoice-address" autocomplete="street-address" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="invoice-country" class="block text-sm font-medium text-gray-700">{{ $t('settings.invoice_country') }}</label>
                        <input v-model="invoice.invoice_country" type="text" name="invoice-country" id="invoice-country" autocomplete="country" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label for="invoice-city" class="block text-sm font-medium text-gray-700">{{ $t('settings.invoice_city') }}</label>
                        <input v-model="invoice.invoice_city" type="text" name="invoice-city" id="invoice-city" autocomplete="locality" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="invoice-zip" class="block text-sm font-medium text-gray-700">{{ $t('settings.invoice_zip') }}</label>
                        <input v-model="invoice.invoice_zip" type="text" name="invoice-zip" id="invoice-zip" autocomplete="postal-code" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-4">
                        <label for="invoice-email" class="block text-sm font-medium text-gray-700">{{ $t('settings.invoice_email') }}</label>
                        <input v-model="invoice.invoice_email" type="text" name="invoice-email" id="invoice-email" autocomplete="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                    </div>

                      <div class="py-5">
                        <div class="border-t border-gray-200"></div>
                      </div>



                    <fieldset>
                      <legend class="sr-only">
                        {{ $t('settings.invoice_type') }}
                      </legend>
                      <div class="bg-white rounded-md -space-y-px">
                        <label :class="invoice.invoice_type == 'email' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer focus:outline-none">
                          <input v-model="invoice.invoice_type" type="radio" name="privacy-setting" value="email" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
                          <div class="ml-3 flex flex-col">
                            <span :class="invoice.invoice_type == 'email' ? 'text-indigo-900' : 'text-gray-900'" id="privacy-setting-0-label" class="block text-sm font-medium">
                              {{ $t('settings.invoice_type_email') }}
                            </span>
                            <span :class="invoice.invoice_type == 'email' ? 'text-indigo-700' : 'text-gray-500'" id="privacy-setting-0-description" class="block text-sm">
                              {{ $t('settings.invoice_type_email_info') }}
                            </span>
                          </div>
                        </label>

                        <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
                        <label :class="invoice.invoice_type == 'mail' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer focus:outline-none">
                          <input v-model="invoice.invoice_type" type="radio" name="privacy-setting" value="mail" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-2-label" aria-describedby="privacy-setting-2-description">
                          <div class="ml-3 flex flex-col">
                            <span :class="invoice.invoice_type == 'mail' ? 'text-indigo-900' : 'text-gray-900'" id="privacy-setting-2-label" class="block text-sm font-medium">
                              {{ $t('settings.invoice_type_mail') }}
                            </span>
                            <span :class="invoice.invoice_type == 'mail' ? 'text-indigo-700' : 'text-gray-500'" id="privacy-setting-2-description" class="block text-sm">
                              {{ $t('settings.invoice_type_mail_info') }}
                            </span>
                          </div>
                        </label>
                      </div>
                    </fieldset>



                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{ $t('buttons.save') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>



        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('settings.database_settings') }}</h3>
                <p class="mt-1 text-sm text-gray-600">
                  {{ $t('settings.database_settings_info') }}
                </p>
                <p class="mt-1 text-sm text-red-600">
                  {{ $t('settings.database_settings_warning') }}
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form @submit.prevent="saveDatabase" action="#" method="POST">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">

                    <fieldset v-if="!database.exists">

                      

                      <div v-for="(database, index) in databases" :key="database.name" class="bg-white rounded-md -space-y-px">
                        <label v-bind:class="[selectedDB == database.name ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', (index == 0) ? 'rounded-tl-md rounded-tr-md' : '']"  class="relative border p-4 flex cursor-pointer focus:outline-none">
                          <input v-model="selectedDB" type="radio" name="privacy-setting" :value="database.id" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
                          <div class="ml-3 flex flex-col">
                            <span :class="selectedDB == database.name ? 'text-indigo-900' : 'text-gray-900'" id="privacy-setting-0-label" class="block text-sm font-medium">
                              {{ database.name }}
                            </span>
                            <span :class="selectedDB == database.name ? 'text-indigo-700' : 'text-gray-500'" id="privacy-setting-0-description" class="block text-sm">
                              {{ database.description }}
                            </span>
                          </div>
                        </label>
                      </div>
                      <label v-bind:class="[selectedDB == 'SELF_HOST' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200']"  class="rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer focus:outline-none">
                          <input v-model="selectedDB" type="radio" name="privacy-setting" value="SELF_HOST" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
                          <div class="ml-3 flex flex-col">
                            <span :class="selectedDB == 'SELF_HOST' ? 'text-indigo-900' : 'text-gray-900'" id="privacy-setting-0-label" class="block text-sm font-medium">
                              {{$t('settings.database_self_hosted')}}
                            </span>
                            <span :class="selectedDB == 'SELF_HOST' ? 'text-indigo-700' : 'text-gray-500'" id="privacy-setting-0-description" class="block text-sm">
                              {{$t('settings.database_self_hosted_info')}}
                            </span>
                          </div>
                        </label>

                      <div class="py-5">
                        <div class="border-t border-gray-200"></div>
                      </div>
                    </fieldset>

                    <div v-if="selectedDB == 'SELF_HOST'" class="grid grid-cols-6 gap-6">
 
                      <div class="col-span-4">
                        <label for="database-host" class="block text-sm font-medium text-gray-700">{{ $t('settings.database_host') }}</label>
                        <input v-if="database.exists" disabled v-model="database.host_hint" type="text" name="database-host" class="bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="!database.exists" v-model="database.host" type="text" name="database-host"  id="database-host" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-2">
                        <label for="database-port" class="block text-sm font-medium text-gray-700">{{ $t('settings.database_port') }}</label>
                        <input v-if="database.exists" disabled v-model="database.port_hint" type="text" class="bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="!database.exists" v-model="database.port" type="text" id="database-port" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6">
                        <label for="database-database" class="block text-sm font-medium text-gray-700">{{ $t('settings.database_name') }}</label>
                        <input v-if="database.exists" disabled v-model="database.database_name_hint" type="text" class="bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="!database.exists" v-model="database.database_name" type="text" id="database-database" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6">
                        <label for="database-username" class="block text-sm font-medium text-gray-700">{{ $t('settings.database_username') }}</label>
                        <input v-if="database.exists" disabled v-model="database.username_hint" type="text"  class="bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="!database.exists" v-model="database.username" type="text"  id="database-username" class=" mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6">
                        <label for="database-password" class="block text-sm font-medium text-gray-700">{{ $t('settings.database_password') }}</label>
                        <input v-if="database.exists" disabled v-model="database.password_hint" type="text" class="bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="!database.exists" v-model="database.password" type="text" id="database-password" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                    </div>


                    <div v-if="this.database.exists" class="bg-white mt-5 mb-5">
                      <div class="relative">
                        <div class="absolute inset-0 h-1/2"></div>
                        <div class="relative max-w-7xl mx-auto  border-gray-100">
                          <div class="max-w-4xl mx-auto">
                            <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                              <div class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                  Tabeller
                                </dt>
                                <dd class="order-1 text-5xl font-extrabold text-indigo-600">
                                  {{database.metrics.tables}}
                                </dd>
                              </div>
                              <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                  Megabyte
                                </dt>
                                <dd class="order-1 text-5xl font-extrabold text-indigo-600">
                                  {{database.metrics.size}}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>

                    



                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button @click="removeDatabaseConnection" v-if="database.exists" type="button" class="mr-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      {{ $t('buttons.change_db_connection') }}
                    </button>
                    <button @click="testDatabaseConnection" v-if="database.exists" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{ $t('buttons.test_db_connection') }}
                    </button>
                    <button v-if="!database.exists" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{ $t('buttons.save') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>


        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('settings.server_settings') }}</h3>
                <p class="mt-1 text-sm text-gray-600">
                  {{ $t('settings.server_settings_info') }}
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
                    
                    
              <ServerList @open="openServerModal = true"  />



            </div>
          </div>
        </div>




        <div class="mt-10 sm:mt-0">
          
        </div>

      </div>
    </div>
    <!-- Footer -->
    <Footer :key="reloader" />
    <Notification  v-if="showNotification"  @hide="showNotification = false" v-bind:title="notificationTitle" v-bind:success="notificationSuccess" v-bind:message="notificationMessage" />
    
  
  </main>
</div>
</template>

<script>


import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import Notification from '../layout/Notification.vue'
import ServerList from '../subcomponents/ServerList.vue'

export default {
  name: 'Settings',
  components: {
    TopNav,
    Footer,
    Notification,
    ServerList,
  },
  data() {
    return {
      openCreateServerModal: true,
      showNotification: false,
      notificationTitle: 'request_success',
      notificationMessage: 'new_data_collector_created',
      notificationSuccess: true,
      selectedDB: "SELF_HOST",
      reloader: 0,
      databases: [],
      customer: {
        company_logo: null,
        company_name: null,
        company_org_number: null,
        company_country: null,
        company_address: null,
        company_zip: null,
        company_city: null,
        company_email: null,
        company_phone: null
      },
      invoice: {
        invoice_country: null,
        invoice_address: null,
        invoice_zip: null,
        invoice_city: null,
        invoice_email: null,
        invoice_type: 'email'
      },
      database: {
        exists: false,
        name: null,
        description: null,
        host: null,
        host_hint: null,
        port: null,
        port_hint: null,
        database_name: null,
        database_name_hint: null,
        username: null,
        username_hint: null,
        password: null,
        password_hint: null,
        metrics: {tables: 0, size: 0}
      },
      company_logo_file: null,
      company_logo_preview: null,
    }
  },
  setup() {

  },
  mounted() {
    this.getCustomer()
    this.getDatabases()
  },
  methods: {
    getCustomer() {
      this.$API.getData('/customer', {})
        .then(response => {
          //this.customer = response.data;
          this.customer.company_logo = response.data.company_logo;
          this.customer.company_name = response.data.company_name;
          this.customer.company_org_number = response.data.company_org_number;
          this.customer.company_country = response.data.company_country;
          this.customer.company_address = response.data.company_address;
          this.customer.company_zip = response.data.company_zip;
          this.customer.company_city = response.data.company_city;
          this.customer.company_email = response.data.company_email;
          this.customer.company_phone = response.data.company_phone;


          this.invoice.invoice_country = response.data.invoice_country;
          this.invoice.invoice_zip = response.data.invoice_zip;
          this.invoice.invoice_city = response.data.invoice_city;
          this.invoice.invoice_address = response.data.invoice_address;
          this.invoice.invoice_email = response.data.invoice_email;
          this.invoice.invoice_type = response.data.invoice_type;

          if(response.data.database_id === null) {
            //this.database = null;
            for (const [key] of Object.entries(this.database)) {
              this.database[key] = null;
              this.database.metrics = response.data.metrics;
            }
            this.database.exists = false;
          } else {
            this.database.exists = true;
            this.database.metrics = response.data.metrics;
            this.database.name = response.data.database.name;
            this.database.description = response.data.database.description;
            this.database.host = response.data.database.host;
            this.database.host_hint = response.data.database.host_hint;
            this.database.port = response.data.database.port;
            this.database.port_hint = response.data.database.port_hint;
            this.database.database_name = response.data.database.database_name;
            this.database.database_name_hint = response.data.database.database_name_hint;
            this.database.username = response.data.database.username;
            this.database.username_hint = response.data.database.username_hint;
            this.database.password = response.data.database.password;
            this.database.password_hint = response.data.database.password_hint;
          }

          let customer = JSON.parse(localStorage.getItem('customer'));
          customer.company_logo = this.customer.company_logo;
          customer.has_db = this.database.exists;
          localStorage.setItem('customer', JSON.stringify(customer));
          this.reloader++;
          
        });
    },
    getDatabases() {
      this.$API.getData('/databases', {})
        .then(response => {
          this.databases = response.data;
          
        });
    },
    handleFileUpload() {
      this.company_logo_file = this.$refs.company_logo_file.files[0];
      this.company_logo_preview = URL.createObjectURL(this.company_logo_file);
    },
    saveCompanyLogo() {
      let file = this.company_logo_file;
      let formData = new FormData();
      formData.append('file', file);
      let url = new URL(this.$API_URL+'/customer')
      url.search = new URLSearchParams({token: localStorage.getItem('token')}).toString();
      fetch(url, { // Your POST endpoint
          method: 'POST',
          headers: {
            'Accept': 'application/json'
          },
          body: formData // This is your file object
        }).then(response => {
          if(response.status == 200) {
            this.company_logo_preview = null;
            this.getCustomer();
            this.showNotification = true;
            this.notificationMessage = 'information_updated';
          }
         
        }).catch(
          error => console.log(error) // Handle the error response object
        );
    },
    saveCustomer() {
      this.$API.postData('/customer', this.customer)
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          console.log(response.data);
        });
    },
    saveInvoice() {
      this.$API.postData('/customer', this.invoice)
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          console.log(response.data);
        });
    },
    installDatabase() {
      this.$API.postData('/customer/database/install', {})
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          this.getCustomer()
          this.getDatabases()
          this.selectedDB = "SELF_HOST"
          console.log(response.data);
        });
    },
    saveDatabase() {
      if(this.selectedDB == "SELF_HOST") {
        this.database.self_hosted = true;
      } else {
        this.database.id = this.selectedDB;
      }
      this.$API.postData('/customer/database', this.database)
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          this.getCustomer()
          this.getDatabases()
          this.installDatabase()
          this.selectedDB = "SELF_HOST"
          console.log(response.data);
        });
    },
    testDatabaseConnection() {
      this.$API.getData('/customer/database', {})
        .then(response => {

          this.notificationMessage = response.message;
          this.showNotification = true;
          this.notificationSuccess = true;
          if(response.status !== 200) {
            this.notificationTitle = 'request_failed';
            this.notificationSuccess = false;
          }
          
          
          console.log(response.data);
        });
    },
    removeDatabaseConnection() {
      this.$API.deleteData('/customer/database', {})
        .then(response => {
          this.showNotification = true;
          this.notificationMessage = 'information_updated';
          let customer = JSON.parse(localStorage.getItem('customer'));
          customer.has_db = false;
          localStorage.setItem('customer', JSON.stringify(customer));
          this.reloader++;
          this.getCustomer()
          console.log(response.data)
        });
    }
  }
  
}
</script>