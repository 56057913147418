<template>


<div class="fixed inset-0 overflow-hidden z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="absolute inset-0 overflow-hidden">
    <!-- Background overlay, show/hide based on slide-over state. -->
    <div class="absolute inset-0" aria-hidden="true">
      <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
        <div class="w-screen max-w-md">
          <div v-on-clickaway="hideModal" class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
            <div class="p-6">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                  Händelser
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button @click="hideModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                    <span class="sr-only">Close panel</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="border-b border-gray-200">
              <div class="px-6">
                <nav class="-mb-px flex space-x-6">
                  <a href="#" class="border-indigo-500 text-indigo-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Alla</a>
                  <a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Varningar</a>
                  <a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">Larm</a>
                </nav>
              </div>
            </div>
            <ul role="list" class="flex-1 divide-y divide-gray-200 overflow-y-auto">
              <li @click="hideModal" v-for="event in events" v-bind:key="event.id" >
                <div class="relative group py-6 px-5 flex items-center">
                  <router-link :to="'/plant/'+event.hierarchy_id" v-slot="{ href, navigate }">
                    <a :href="href" @click="navigate" class="-m-1 flex-1 block p-1">
                      <div class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"></div>
                      <div class="flex-1 flex items-center min-w-0 relative">
                        <span class="flex-shrink-0 inline-block relative">
                          
                          <svg v-if="event.type == 'stop_collector'" class="flex-shrink-0 h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                          <svg v-if="event.type == 'data_collector'" class="flex-shrink-0 h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                          </svg>
                          <svg v-if="event.type == 'hierarchy_node'" class="flex-shrink-0 h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                          </svg>
                          <svg v-if="event.type == 'sensor'" class="flex-shrink-0 h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                          </svg>

                        </span>
                        <div class="ml-4 truncate">
                          <p class="text-sm font-medium text-gray-900 truncate">{{event.name}}</p>
                          <p class="text-sm text-gray-500 truncate">{{ $t('logs.'+event.text)}}</p>
                        </div>
                      </div>
                    </a>
                  </router-link>
                  <div class="ml-2 flex-shrink-0 relative inline-block text-left">
                    <button type="button" class="group relative w-8 h-8 bg-white rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                      <span class="sr-only">Open options menu</span>
                      <span class="flex items-center justify-center h-full w-full rounded-full">
                        <!-- Heroicon name: solid/dots-vertical -->
                        <svg class="w-5 h-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="relative">

  <div class="absolute z-20 right-0 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
    <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          <router-link v-for="event in events" v-bind:key="event.id" :to="'/plant/'+event.hierarchy_id" v-slot="{ href, navigate }">
        <a :href="href" @click="navigate" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">


            <svg v-if="event.type == 'stop_collector'" class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <svg v-if="event.type == 'data_collector'" class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>
            <svg v-if="event.type == 'hierarchy_node'" class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
            </svg>
            <svg v-if="event.type == 'sensor'" class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
            </svg>

          <div class="ml-4">
            <p class="text-base font-medium text-gray-900">
              {{event.name}}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ $t('logs.'+event.text)}}
            </p>
            <span class="mt-1 text-xs text-gray-400">{{event.log_date}}</span>
          </div>
        </a>
          </router-link>

      </div>
      <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
        <div class="flow-root">
          <router-link to="/events" v-slot="{ href, navigate }" custom>
            <a :href="href" @click="navigate" class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150">

                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <span class="ml-3">{{$t('logs.show_all_logs')}}</span>
            </a>
          </router-link>

        </div>

      </div>
    </div>
  </div>
</div>
-->
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
export default {
    name: 'Events',
    props: ['events'],
    mixins: [ clickaway ],
    methods: {
      hideModal() {
          console.log("hide");
            this.$emit('hide');
        },
    }
}
</script>