<template>
    <nav class="bg-white shadow">
      
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button -->
        <button @click="showMobileMenu = !showMobileMenu" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg v-if="!showMobileMenu" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg v-if="showMobileMenu" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="block lg:hidden h-8 w-auto" v-if="logo !== null" :src="logo" alt="Workflow">
          <img class="hidden lg:block h-8 w-auto" v-if="logo !== null" :src="logo" alt="Workflow">
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
          <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->

        <router-link
        v-if="has_db"
        to="/dashboard"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.dashboard') }}</a>
        </router-link>

        <router-link
        v-if="has_db"
        to="/plant"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.plant') }}</a>
        </router-link>
<!--
        <router-link
        v-if="has_db"
        to="/data"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.data') }}</a>
        </router-link>

        <router-link
        v-if="has_db"
        to="/stops"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.stops') }}</a>
        </router-link>
-->
        <router-link
        v-if="has_db && (user.is_sys_admin)"
        to="/settings"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.settings') }}</a>
        </router-link>
        
        <router-link
        v-if="!has_db && (user.is_sys_admin)"
        to="/settings"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'border-red-500 text-red-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', !isActive && 'border-transparent text-red-500 hover:border-red-300 hover:text-red-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :href="href" @click="navigate">{{ $t('nav.settings') }}</a>
        </router-link>

        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <span class="inline-block relative">
        <button @click="showEventsMenu = true" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="sr-only">View notifications</span>
          <!-- Heroicon name: outline/bell -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
        </button>
        <!--<span v-if="events.length > 0" class="absolute top-0 right-2 transform translate-y-1/2 translate-x-1/2 block border-2 border-white rounded-full">
            <span class="block h-2 w-2 rounded-full bg-red-400"></span>
          </span>-->
        </span>


        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <router-link
              to="/profile"
              v-slot="{ href, navigate }"
              custom
            >
              <a class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :href="href" @click="navigate">
                <span class="sr-only">Open user menu</span>
                <img v-if="user.profile_picture !== null" class="h-8 w-8 rounded-full" :src="$API_URL+'/uploads/'+user.profile_picture" alt="">
                <svg v-if="user.profile_picture === null" class="h-8 w-8 rounded-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

<Events  v-bind:events="events" v-if="showEventsMenu" @hide="showEventsMenu = false" />

  <div v-if="showMobileMenu" class="sm:hidden" id="mobile-menu">
    <div class="pt-2 pb-3 space-y-1">
        <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :href="href" @click="navigate">{{ $t('nav.dashboard') }}</a>
        </router-link>

        <router-link
        to="/plant"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :href="href" @click="navigate">{{ $t('nav.plant') }}</a>
        </router-link>

        <router-link
        v-if="user.is_admin || user.is_sys_admin"
        to="/settings"
        v-slot="{ href, navigate, isActive }"
        custom
        >
            <a :class="[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :href="href" @click="navigate">{{ $t('nav.settings') }}</a>
        </router-link>
    
    
    </div>
    <div class="pt-4 pb-3 border-t border-gray-200">
      <div class="flex items-center px-4">
        <div class="flex-shrink-0">
          <img v-if="user.profile_picture !== null" class="h-10 w-10 rounded-full" :src="$API_URL+'/uploads/'+user.profile_picture" alt="">
          <svg v-if="user.profile_picture === null" class="h-10 w-10 rounded-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>
        <div class="ml-3">
          <div class="text-base font-medium text-gray-800">{{user.firstname}} {{user.lastname}}</div>
          <div class="text-sm font-medium text-gray-500">{{user.email}}</div>
        </div>
      </div>
      <div class="mt-3 space-y-1">
        <router-link
          to="/profile"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a :class="[isActive && 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', !isActive && 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :href="href" @click="navigate">{{ $t('nav.profile') }}</a>
        </router-link>
        <a href="#" class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">{{ $t('nav.logout') }}</a>
      </div>
    </div>
  </div>
</nav>

</template>
<script>

import Events from './Events.vue'
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [ clickaway ],
    name: 'TopNav',
    components: {
      Events,
    },
    data() {
      return {
        showEventsMenu: false,
        showMobileMenu: false,
        events: [],
        user: JSON.parse(localStorage.getItem('user')),
        has_db: false,
        logo: null,
      }
    },
    mounted() {

      let customer = JSON.parse(localStorage.getItem('customer'));
      if(customer.company_logo !== null) {
        this.logo = this.$API_URL+'/uploads/'+customer.company_logo;
      } else {
        this.logo = '/logo-lg.png';
      }
      this.has_db = customer.has_db;
      this.checkToken();
      this.getEvents();
    },
    methods: {
      away() {
        this.showEventsMenu = false
      },
      checkToken() {
        this.$API.getData('/auth')
        .then(response => {
          if(response.status != 200) {
            this.$router.push('/')
          } else {
            if(typeof response.data.customer !== 'undefined' && response.data.customer !== null) {
              localStorage.setItem('customer', JSON.stringify(response.data.customer));
            }
            if(typeof response.data.user !== 'undefined' && response.data.user !== null) {
              localStorage.setItem('user', JSON.stringify(response.data.user));
            }
          }
        });
      },
      getEvents() {
          this.$API.getData('/events')
          .then(response => {
              this.events = response.data;
          });
      },
    }
}
</script>
