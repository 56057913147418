<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100"
    leave-to-class="opacity-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    </transition>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="mt-3  sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
             {{ $t('modals.create_public_view') }}
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500 mb-10">
              {{ $t('modals.create_public_view_description') }}
            </p>
            <div class="mb-5">
                <label for="location" class="block text-sm font-medium text-gray-700">{{ $t('modals.access_period') }}</label>
                <select v-model="period" id="location" name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option value="1h" selected>{{ $t('modals.1h') }}</option>
                    <option value="2h" selected>{{ $t('modals.2h') }}</option>
                    <option value="4h" selected>{{ $t('modals.4h') }}</option>
                    <option value="8h" selected>{{ $t('modals.8h') }}</option>
                    <option value="24h" selected>{{ $t('modals.24h') }}</option>
                    <option value="1w" selected>{{ $t('modals.1w') }}</option>
                    <option value="2w" selected>{{ $t('modals.2w') }}</option>
                    <option value="1m" selected>{{ $t('modals.1m') }}</option>
                    <option value="2m" selected>{{ $t('modals.2m') }}</option>
                    <option value="3m" selected>{{ $t('modals.3m') }}</option>
                    <option value="6m" selected>{{ $t('modals.6m') }}</option>
                    <option value="1y" selected>{{ $t('modals.1y') }}</option>
                    <option value="infinite" selected>{{ $t('modals.infinite') }}</option>
                </select>
            </div>            
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button @click="saveServer()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          {{ $t('buttons.save') }}
        </button>
        <button @click="hideModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          {{ $t('buttons.cancel') }}
        </button>
      </div>
    </div>
    </transition>
  </div>
</div>
</template>

<script>

export default {
    name: 'CreatePublicViewModal',
    props: ['hierarchy_id', 'meta_id'], 
    data() {
        return {
          period: "1w"
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide');
        },
        refresh(url) {
            this.$emit('refresh', url);
        },
        saveServer() {
            this.$API.postData('/datalogging/share', { hierarchy_id: this.hierarchy_id, meta_id: this.meta_id, period: this.period })
            .then(response => {
                if(response.status == 200) {
                    this.hideModal()
                    this.refresh(response.data.view.url)
                }
            });
        }
        
    }
}
</script>