<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100"
    leave-to-class="opacity-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    </transition>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="mt-3  sm:mt-5">
          <div class="mt-2">
            <div class="mb-5">
                <input type="url" readonly :value="$APP_URL + '/v/' + url" id="location" name="location" class="mt-1 block w-full pl-3 pr-10 py-2  text-base text-center border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            </div>            
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
        <button @click="hideModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          {{ $t('buttons.close') }}
        </button>
      </div>
    </div>
    </transition>
  </div>
</div>
</template>

<script>

export default {
    name: 'PublicViewUrlModal',
    props: ['url'], 
    data() {
        return {
          period: "1w"
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide');
        },
    }
}
</script>