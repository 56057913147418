<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div>
<TopNav/>


  <main>
    <div v-if="current.is_customer && hierarchy.length == 0" class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="text-center">
        <svg class="mx-auto h-20 w-20 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <h3 class="mt-2 text-sm font-medium text-gray-900">{{$t('general.welcome_title')}}</h3>
        <p class="mt-1 text-sm text-gray-500">
          {{$t('general.welcome_description')}}
        </p>
        <div class="mt-6">
          <button @click="openCreateHierarchyModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/plus -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
            </svg>
            {{$t('general.welcome_button')}}
          </button>
        </div>
      </div>
    </div>
    <div v-if="!current.is_customer || hierarchy.length != 0" class="max-w-12xl mx-auto py-12 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto">

        <!--<MachineState v-if="!current.is_customer && current.stop_table !== null"  />-->

        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
          
            <div class="lg:flex lg:items-center lg:justify-between mb-5 pb-5 border-b border-gray-200">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{ current.is_customer ? 'Anläggning' : current.name }}  
                </h2>
                <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: solid/calendar -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    {{ current.created_at }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex lg:mt-0 lg:ml-4" v-if="hierarchy.length > 0 || (current.data_table !== null || current.stop_table !== null)">
                <span v-if="!current.is_customer && (user.is_sys_admin || user.is_admin || current.data_table !== null)">
                  <router-link
                      :to="'/plant/'+current.id+'/data'"
                      v-slot="{ href, navigate }"
                      custom
                    >
                    <a :href="href" @click="navigate" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                    </svg>
                    <span class="ml-2 hidden md:block">{{ $t('plant.data_collector') }}</span>
                    </a>
                  </router-link>
                </span>

                <span  v-if="!current.is_customer && (user.is_sys_admin || user.is_admin || current.stop_table !== null)" class="ml-3">
                  <router-link
                      :to="'/plant/'+current.id+'/stops'"
                      v-slot="{ href, navigate }"
                      custom
                    >
                    <a :href="href" @click="navigate" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/link -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span class="ml-2 hidden md:block">{{ $t('plant.stop_collector') }}</span>
                  </a>
                  </router-link>
                </span>

                <span v-if="user.is_sys_admin || user.is_admin" class="ml-3">
                  <button @click="openCreateHierarchyModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                  </button>
                </span>

                <span v-if="user.is_sys_admin || user.is_admin" class="ml-3">
                  <button @click="openCreateHierarchyUserModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </button>
                </span>
<!--
                <span v-if="!current.is_customer && (user.is_sys_admin || user.is_admin)" class="ml-3">
                  <button @click="openShareModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                  </button>
                </span>
-->
                <span v-if="!current.is_customer && (user.is_sys_admin || user.is_admin)" class="ml-3">
                  <button @click="openEditHierarchyModal = true" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: solid/check -->
                    <svg class="-ml-1 -mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                  </button>
                </span>

              </div>
            </div>

            

            <div v-if="hierarchy.length > 0" class="rounded bg-white w-full ">
              <div class="bg-white shadow overflow-hidden sm:rounded-md">
                <ul class="divide-y divide-gray-200">
                  <li v-for="node in hierarchy" :key="node.id">
                    <router-link
                      :to="'/plant/'+node.id"
                      v-slot="{ href, navigate }"
                      custom
                    >
                    <a :href="href" @click="navigate"  class="block hover:bg-gray-50">
                      <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                          <div class="flex-shrink-0">
                            <span class="h-12 w-12 inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                              <span class="text-md font-medium leading-none text-white">{{node.name.substring(0,2).toUpperCase()}}</span>
                            </span>
                          </div>
                          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div>
                              <p class="text-sm font-medium text-indigo-600 truncate">{{ node.name }}</p>
                              <p class="mt-2 flex items-center text-sm text-gray-500">
                                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                </svg>
                                <span class="truncate" v-if="node.children.length > 0">
                                  {{ node.children.length }} Underkategori{{node.children.length > 1 ? "er" : ""}}
                                </span>
                                <span class="truncate" v-if="node.children.length == 0">
                                  Inga underkategorier
                                </span>
                              </p>
                            </div>
                            <div class="hidden md:block">
                              <div>
                                <p class="text-sm text-gray-900">
                                  {{ $t('general.node_type') }}
                                  {{ ' ' }}
                                  <!--<time :datetime="application.date">{{ application.dateFull }}</time>-->
                                </p>
                                <p class="mt-2 flex items-center text-sm text-gray-500">
                                  <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                  </svg>
                                  {{ $t('general.'+node.type) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    </a>
                    </router-link>
                  </li>
                </ul>
              </div>  
            </div>

            <div v-if="hierarchy.length == 0 && current.stop_table !== null" class="rounded bg-white w-full mb-10">
              <div class="bg-white shadow overflow-hidden sm:rounded-md">
                <StopChart v-bind:series="stops" />
              </div>  
            </div>

            <div v-if="hierarchy.length == 0 && current.data_table !== null" class="rounded bg-white w-full">
              <div class="bg-white shadow overflow-hidden sm:rounded-md">
                <DataList :nodes="datapoints" :current="current" />
              </div>  
            </div>

            <div v-if="hierarchy.length == 0 && current.data_table === null && current.stop_table === null && (user.is_sys_admin || user.is_admin)" class="rounded bg-white w-full">
              <div class="max-w-xl mx-auto ">
                <div class="text-center">
                  <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Kom igång </h1>
                </div>
                <div class="mt-12">
                  <ul  role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/book-open -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none" @click.prevent="openCreateHierarchyModal = true">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Skapa ny underkategori
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Bygg vidare på er anläggningsstruktur</p>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/view-list -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <a href="#" class="focus:outline-none" @click.prevent="openCreateHierarchyUserModal = true">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Användare
                            </a>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Ge dina kollegor möjlighet att fördjupa sig i er data</p>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/bookmark-alt -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <router-link
                              :to="'/plant/'+current.id+'/data'"
                              v-slot="{ href, navigate }"
                              custom
                            >
                            <a :href="href" @click="navigate" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Datainsamling
                            </a>
                            </router-link>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Skapa en ny datainsamling på den här platsen.</p>
                      </div>
                    </li>

                    <li class="relative py-6 flex items-start space-x-4">
                      <div class="flex-shrink-0">
                        <span class="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                          <!-- Heroicon name: outline/rss -->
                          <svg class="h-6 w-6 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                        </span>
                      </div>
                      <div class="min-w-0 flex-1">
                        <h3 class="text-base font-medium text-gray-900">
                          <span class="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <router-link
                              :to="'/plant/'+current.id+'/stops'"
                              v-slot="{ href, navigate }"
                              custom
                            >
                            <a :href="href" @click="navigate" class="focus:outline-none">
                              <span class="absolute inset-0" aria-hidden="true"></span>
                              Avvikelser
                            </a>
                            </router-link>
                          </span>
                        </h3>
                        <p class="text-base text-gray-500">Börja mät avvikelser och tillgänglighet</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div v-if="hierarchy.length == 0 && current.data_table === null && current.stop_table === null && user.is_user" class="text-center">
              <EmptyState />
            </div>

          </div>
        </div>

      </div>
    </div>
    <!-- Footer -->
    <Footer />
    <CreateHierarchyModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyModal"  @hide="openCreateHierarchyModal = false" @refresh="getHierarchyChildren()" />
    <CreateHierarchyUserModal :parent_id="current.is_customer ? null : current.id" v-if="openCreateHierarchyUserModal"  @hide="openCreateHierarchyUserModal = false" @refresh="getHierarchyChildren()" />
    <EditHierarchyModal  :current="current" v-if="openEditHierarchyModal"  @hide="openEditHierarchyModal = false" @refresh="getHierarchyChildren()" />
  
  </main>
</div>
</template>

<script>
import TopNav from '../layout/TopNav.vue'
import Footer from '../layout/Footer.vue'
import CreateHierarchyModal from '../modals/CreateHierarchyModal.vue'
import EditHierarchyModal from '../modals/EditHierarchyModal.vue'
import CreateHierarchyUserModal from '../modals/CreateHierarchyUserModal.vue'
import DataList from '../subcomponents/DataList.vue'
import EmptyState from '../subcomponents/EmptyState.vue'
import StopChart from '../charts/StopChart.vue'


export default {
  name: 'HelloWorld',
  components: {
    TopNav,
    Footer,
    CreateHierarchyModal,
    EditHierarchyModal,
    CreateHierarchyUserModal,
    DataList,
    StopChart,
    EmptyState
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      hierarchy: {},
      datapoints: [],
      stops: [],
      current: {},
      customer: {},
      openShareModal: false,
      openEditHierarchyModal: false,
      openCreateHierarchyModal: false,
      openCreateHierarchyUserModal: false,
      reload: 0,
    }
  },
  mounted(){
    this.checkToken()
  },
  watch: {
    "$route.params.id": {
      handler () {
        this.nodes = [];
        this.getHierarchyChildren();
      },
      immediate: true 
    }
  },
  methods: {
    checkToken() {
      
    },
    getHierarchyChildren() {
      
      this.$API.getData('/hierarchy', {id: this.$route.params.id})
        .then(response => {
          this.hierarchy = response.data.hierarchy;
          this.customer = response.data.customer;
          this.current = response.data.current;
          
          if(this.current === null) {
            this.current = this.customer;
            this.current.is_customer = true;
          } else {
            this.current.is_customer = false;
            if(this.current.data_table !== null) {
              this.getDataLogging()
            }
            if(this.current.stop_table !== null) {
              this.getStopLogging()
            }
          }
        }).catch(response => {
          console.log(response);
          this.$router.push('/plant')
        });
    },
    getDataLogging() {
      this.$API.getData('/datalogging',  {id: this.$route.params.id})
      .then(response => {
          if(response.metaTable != null){
              this.datapoints = response.metaTable;
          }
      });
    },
    getStopLogging() {
      this.$API.getData('/stoplogging',  {id: this.$route.params.id})
      .then(response => {
          if(response.stopTable != null){
              this.stops = response.series;
          }
      });
    }
  }
}
</script>