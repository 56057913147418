<template>
  <ul class="divide-y divide-gray-200">
    <li v-for="node in nodes" :key="node.id">
      <router-link
        :to="'/plant/'+current.id+'/data/'+node.id"
        v-slot="{ href, navigate }"
        custom
      >
      <a :href="href" @click="navigate"  class="block hover:bg-gray-50">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="flex-shrink-0">
              <h2 class="text-2xl font-bold leading-7 text-indigo-700 sm:text-3xl sm:truncate">
                <span v-if="node.data.length > 0">{{Math.round(node.data[node.data.length-1])}}{{node.unit}}</span>
                <span v-if="node.data.length == 0">--{{node.unit}}</span>
                </h2>
            </div>
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p class="text-sm font-medium text-indigo-600 truncate">{{ node.friendly_name ? node.friendly_name : node.name }}</p>
                <p class="mt-2 flex items-center text-sm text-gray-500">
                  {{ node.name }}
                </p>
              </div>
              <div>
                <SparklineChart :key="(node.id+Math.random())" v-bind:node="node" />
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import SparklineChart from '../charts/SparklineChart.vue'
export default {
    name: 'DataList',
    props: ['nodes', 'current'],
    components: {
      SparklineChart
    }
}
</script>