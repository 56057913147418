<template>
  <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
    <div v-if="!registered">
      <div class="max-w-3xl mx-auto">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{$t('register.register_account')}}
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

        <div class="rounded-md bg-red-50 p-4 mb-5" v-if="error">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{$t('logs.'+error_message)}}
              </h3>
            </div>
          </div>
        </div>

        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6" action="#" method="POST" @submit.prevent="register">

            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{$t('register.company_settings')}}
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{$t('register.company_settings_info')}}
              </p>
            </div>

            
            <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
              <label for="company_name" class="block text-xs font-medium text-gray-900">{{$t('register.company_name')}}</label>
              <input v-model="company.company_name" type="text" name="company_name" id="company_name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
            </div>

            <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
              <label for="email" class="block text-xs font-medium text-gray-900">{{$t('register.email')}}</label>
              <input v-model="user.email" type="email" name="email" id="email" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
            </div>

            <div class="relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center">
                <span class="px-2 bg-white text-sm text-gray-500">
                  Är du mänsklig?
                </span>
              </div>
            </div>
            <img :src="base64_captcha">
            <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
              <label for="captcha" class="block text-xs font-medium text-gray-900">{{$t('register.captcha')}}</label>
              <input autocomplete="off" v-model="captcha" type="text" name="captcha" id="captcha" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
            </div>

            <div class="mb-6">
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{$t('register.register')}}
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
    <div v-if="registered">
      <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <h3 class="mt-2 text-sm font-medium text-gray-900">Alright!</h3>
        <p class="mt-1 text-sm text-gray-500">
          Ditt konto är registrerat!<br>Vi har mailat ut en länk som du behöver klicka på för aktivering, därefter kan du logga in!
        </p>
        <div class="mt-6">
          <router-link
            :to="'/'"
            v-slot="{ href, navigate }"
            custom
          >
            <a :href="href" @click="navigate"   class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Gå till inloggning
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Register',
  components: {
      
  },
  data() {
    return {
      error: false,
      error_message: null,
      registered: false,
      base64_captcha: null,
      captcha: null,
      token: null,
      user: {
        email: null,
      },
      company: {
        company_name: null
      }
    }
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      this.$API.getData('/captcha', { ctoken: localStorage.getItem('ctoken') })
        .then(response => {
            if(response.status == 200) {
              localStorage.setItem('ctoken', response.data.token);
              this.base64_captcha = response.data.message;
              this.token = response.data.token;
            }
        });
    },

    register() {
      if(this.user.email != "" && this.company.company_name != "") {
        this.$API.postData('/register', { token: this.token, captcha: this.captcha, user: this.user, company: this.company })
            .then(response => {
                if(response.status == 200) {
                  this.registered = true;
                } else {
                  this.error = true;
                  this.error_message = response.data.message;
                }
            });
      }

    }
  }
}
</script>