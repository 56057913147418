<template>
    <div>
      <!--<div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
        <div class="sm:col-span-2">
          <label for="from-date" class="block text-sm font-medium text-gray-700">
            {{ $t('general.from_datetime') }}
          </label>
          <div class="mt-1">
            <input type="date" name="from-date" id="from-date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="to-date" class="block text-sm font-medium text-gray-700">
            {{ $t('general.to_datetime') }}
          </label>
          <div class="mt-1">
            <input type="date" name="to-date" id="to-date" autocomplete="postal-code" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>-->
      <apexchart ref="realtimeChart"  :options="options" :series="series"></apexchart>
    </div>
</template>

<script>

export default {
  name: 'LineChart',
  components: {
  },
  data() {
    return {
      options: {
        colors:['#6366f1'],
        fill: {
            colors: ['#6366f1']
        },
        chart: {
          height: 200,
          id: 'vuechart-example',
          type: 'area',
          toolbar: {
            show: false
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        tooltip: {
          x: {
              show: true,
              format: 'dd MMM - HH:mm:ss',
              formatter: undefined,
          }
        }
      },
      series: [{
        name: '',
        data: []
      }]
    }
  },
  mounted() {
  },
  methods: {
    getRandomArbitrary() {
      return Math.floor(Math.random() * 99);
    },
    dataToChart(data, dtName = null){
     var dataValues = [];
     this.series[0].data = [];
      for (var i = 0; i < data.length; i++) { 
         var dataPair = {};
          dataPair.x = data[i].log_date;
          dataPair.y = parseFloat(data[i].log_value);
          dataValues.push(dataPair);
      }
      this.series[0].data = dataValues;
      this.series[0].name = dtName;
      this.updateSeriesLine()
    },
    updateSeriesLine() {
      if(typeof this.$refs.realtimeChart != "undefined"){
          this.$refs.realtimeChart.updateSeries([{
            name: this.series[0].name,
            data: this.series[0].data,
          }], false, true);
      }
    }
  }
}
</script>
