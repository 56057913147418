<template>
      <apexchart ref="realtimeChart" height="900" :options="options" :series="series"></apexchart>
</template>

<script>

export default {
  name: 'LineChart',
  components: {
  },
  data() {
    return {
      options: {
        colors:['#6366f1'],
        fill: {
            colors: ['#6366f1']
        },
        chart: {
          id: 'vuechart-example',
          type: 'area',
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false,
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            style: {
                  colors: [],
                  fontSize: '20px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  cssClass: 'apexcharts-xaxis-label',
              }
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
            style: {
              colors: [],
              fontSize: '20px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          }
        },
        tooltip: {
          x: {
              show: true,
              format: 'dd MMM - HH:mm:ss',
              formatter: undefined,
              
          }
        }
      },
      series: [{
        name: '',
        data: []
      }]
    }
  },
  mounted() {
  },
  methods: {
    getRandomArbitrary() {
      return Math.floor(Math.random() * 99);
    },
    dataToChart(data, dtName = null){
     var dataValues = [];
     this.series[0].data = [];
      for (var i = 0; i < data.length; i++) { 
         var dataPair = {};
          dataPair.x = data[i].log_date;
          dataPair.y = parseFloat(data[i].log_value);
          dataValues.push(dataPair);
      }
      this.series[0].data = dataValues;
      this.series[0].name = dtName;
      this.updateSeriesLine()
    },
    updateSeriesLine() {
      if(typeof this.$refs.realtimeChart != "undefined"){
          this.$refs.realtimeChart.updateSeries([{
            name: this.series[0].name,
            data: this.series[0].data,
          }], false, true);
      }
    }
  }
}
</script>
