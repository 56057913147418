<template>
    <apexchart  height="150" :options="options" :series="series"></apexchart>
</template>
<script>
import moment from 'moment'
export default {
  name: 'StopChart',
  props: ['series'],
  components: {
  },
  data() {
    let self = this;
    return {
        options: {
        colors:['#10B981', '#EF4444'],
        chart: {
          type: 'rangeBar'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        tooltip: {
          enabled: true,
          x: {
              show: false,
              format: 'HH:mm:ss',
              formatter: undefined,
          },
          y: {
              formatter: undefined,
              title: {
                  formatter: (seriesName) => seriesName,
              },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return self.diffDate(val[0], val[1]);

          }
        },
      }
    }
  },
  methods: {
          diffDate(from, to) {
       
        var a = moment(from)
        var b = moment(to)
        var ss = b.diff(a, 'seconds');

        let parts = [];
        const duration = moment.duration(ss, 'seconds');

        if(!duration || duration.toISOString() === "P0D") return;

        if(duration.years() >= 1) {
            const years = Math.floor(duration.years());
            parts.push(years+" "+(years > 1 ? this.$t('general.years') : this.$t('general.year')));
        }

        if(duration.months() >= 1) {
            const months = Math.floor(duration.months());
            parts.push(months+" "+(months > 1 ? this.$t('general.months') : this.$t('general.month')));
        }

        if(duration.days() >= 1) {
            const days = Math.floor(duration.days());
            parts.push(days+" "+(days > 1 ? this.$t('general.days') : this.$t('general.day')));
        }

        if(duration.hours() >= 1) {
            const hours = Math.floor(duration.hours());
            parts.push(hours+" "+(hours > 1 ? this.$t('general.hours') : this.$t('general.hour')));
        }

        if(duration.minutes() >= 1) {
            const minutes = Math.floor(duration.minutes());
            parts.push(minutes+" "+(minutes > 1 ? this.$t('general.minutes') : this.$t('general.minute')));
        }

        if(duration.seconds() >= 1) {
            const seconds = Math.floor(duration.seconds());
            parts.push(seconds+" "+(seconds > 1 ? this.$t('general.seconds') : this.$t('general.second')));
        }

        return parts.join(", ");
      },
  }
}
</script>
