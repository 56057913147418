<template>
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                Fil
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell">
                Datum
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden md:table-cell">
                Rader
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">{{$t('buttons.download')}}</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="dataExport in dataExports" v-bind:key="dataExport.id">
              <td class="px-6 py-4 flex-1 flex items-center whitespace-nowrap">
                    <svg v-if="dataExport.static == 1" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                    </svg>
                    <svg v-if="dataExport.static != 1" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span class="ml-2 flex-1 w-20 truncate">
                        {{ dataExport.filename }}
                    </span>
              </td>
              <td class="px-6 py-4 hidden md:table-cell">
                {{ dataExport.export_date }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                {{ dataExport.rows }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a :href="$API_URL + '/exports/' + dataExport.filename" target="_blank" class="text-indigo-600 hover:text-indigo-900">{{$t('buttons.download')}} (.{{dataExport.type}})</a>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: 'DataExports',
    props: ['dataExports'],
    data() {
        return {
        }
    },
    mounted() {
      
      console.log(this.dataExports);
    },
    methods: {

    }
}
</script>