<!-- ./src/App.vue -->

<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'App',
};
</script>