<template>
    <div>
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h3 class="mt-2 text-md font-medium text-gray-900">Här var det tomt</h3>
        <p class="mt-1 text-sm text-gray-500">
        Du har tyvärr inte tillräckliga behörigheter för att påbörja något här.
        </p>
        <div class="mt-6">
        <button @click="$router.go(-1)" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/plus -->
            Tillbaka
        </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EmptyState',
    props: [''],
    components: {
    }
}
</script>