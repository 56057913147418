<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div v-show="!error">
      <PublicLineChart  v-show="loggerData" ref="chart" />
      <div class="mt-5 bg-white">
        <div class="relative">
          <div class="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="mx-auto">
              <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    MIN
                  </dt>
                  <dd class="order-1 text-5xl font-extrabold text-indigo-600">
                    {{summary.min}}{{sensor.unit}}
                  </dd>
                </div>
                <div class="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    SNITT
                  </dt>
                  <dd class="order-1 text-5xl font-extrabold text-indigo-600">
                    {{summary.avg}}{{sensor.unit}}
                  </dd>
                </div>
                <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    MAX
                  </dt>
                  <dd class="order-1 text-5xl font-extrabold text-indigo-600">
                    {{summary.max}}{{sensor.unit}}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="error" class="h-screen">
      <main class="min-h-full bg-cover bg-top sm:bg-top" style="background-image: url('https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75');">
        <div class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p class="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">404 THE PAGE COULD NOT BE FOUND</p>
          <h1 class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Uh oh! I think you’re lost.</h1>
          <p class="mt-2 text-lg font-medium text-black text-opacity-50">The view you tried to reach has never existed or has expired..</p>
          <div class="mt-6">
            <a href="/" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50">
              This way back to safety
            </a>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import PublicLineChart from '../charts/PublicLineChart.vue'


const average = arr => arr.reduce((a,b) => a + b, 0) / arr.length;

export default {
  name: 'PublicView',
  components: {
    PublicLineChart,
  },
  data() {
    return {
      error: false,
      summary: {
        min: null,
        max: null,
        avg: null,
        current: null
      },
      hierarchy: {},
      sensor: {},
      current: {},
      customer: {},
      dataLoggInterval: 0,
      loggerData: true,
      dataExports: [],
      dates: {
        date1: null,
        date2: null
      },
      openShareModal: false,
    }
  },
  mounted(){
    this.getDataLogging()
    this.getSensor()
    this.startLineChart()
  },
  watch: {
    "$route.params.id": {
      handler () {
        //this.getDataLogging();
      },
      immediate: true 
    }
  },
  methods: {
    startLineChart() {
     this.dataLoggInterval = setInterval(() => {
        this.getDataLogging();
      }, 10000);
    },
    getSensor() {
      this.$API.getData('/public/sensor',  { key: this.$route.params.key })
      .then(response => {
        if(response.status == 200) {
          this.error = false;
          this.sensor = response.data.sensor;
        } else {
          this.error = true;
        }
        
      });
    },
    getDataLogging() {
        this.$API.getData('/public/getChartData',  { key: this.$route.params.key })
          .then(response => {
            if(response.status == 200) {
              var data = response.data;
              let values = [];

              data.forEach((value) => {
                let val = parseFloat(value.log_value);
                if(this.summary.min == null) {
                  this.summary.min = val;
                } else if(this.summary.min > val) {
                  this.summary.min = val;
                }

                if(this.summary.max == null) {
                  this.summary.max = val;
                } else if(this.summary.max < val) {
                  this.summary.max = val;
                }

                values.push(val);
                this.summary.current = val;
              });

              this.summary.avg = average(values).toFixed(2)

              if(data.length > 0){
                this.loggerData = true;
                var dtname = response.metaName;
                if(typeof this.$refs.chart != "undefined") {
                    this.$refs.chart.dataToChart(data,dtname);
                }
              } else {
                clearInterval(this.dataLoggInterval)
                this.loggerData = false;
              }
            }
      });
    },
  }
}
</script>