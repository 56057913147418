<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-16 w-auto" v-if="company_logo !== null" :src="company_logo" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{$t('login.activate_account')}}
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" action="#" method="POST" @submit.prevent="activate">

          <div class="border border-gray-300 bg-gray-100 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="email" class="block text-xs font-medium text-gray-900">{{$t('profile.email')}}</label>
            <input v-model="email" readonly type="email" name="email" id="email" class="block w-full bg-gray-100 border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>

          <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="firstname" class="block text-xs font-medium text-gray-900">{{$t('profile.firstname')}}</label>
            <input v-model="firstname" type="text" name="firstname" id="firstname" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>

          <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="lastname" class="block text-xs font-medium text-gray-900">{{$t('profile.lastname')}}</label>
            <input v-model="lastname" type="text" name="lastname" id="lastname" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>

          <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="phone" class="block text-xs font-medium text-gray-900">{{$t('profile.phone')}}</label>
            <input v-model="phone" type="text" name="phone" id="phone" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>

          <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="password" class="block text-xs font-medium text-gray-900">{{$t('profile.password')}}</label>
            <input v-model="password" type="password" name="password" id="password" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>

          <div class="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
            <label for="password_repeat" class="block text-xs font-medium text-gray-900">{{$t('profile.password_repeat')}}</label>
            <input v-model="password_repeat" type="password" name="password_repeat" id="password_repeat" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
          </div>


          <div class="mb-6">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {{$t('buttons.activate')}}
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Activate',
  components: {
      
  },
  data() {
    return {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        password: null,
        password_repeat: null,
        activation_key: null,
        company_logo: null,
    }
  },
  beforeMount(){
    this.activation_key = this.$route.params.token;
    console.log(this.activation_key);
    this.checkActivation()
  },
  methods: {
    checkActivation() {
      console.log(this.activation_key);
      this.$API.getData('/activation', { key: this.activation_key })
            .then(response => {
                if(response.status == 200) {
                  console.log(response)
                  this.email = response.data.user.email;
                  if(response.data.customer.company_logo !== null) {
                    this.company_logo = this.$API_URL+'/uploads/'+response.data.customer.company_logo;
                  } else {
                    this.company_logo = '/logo-lg.png';
                  }
                }
            });
    },
    activate() {
      if(this.password == this.password_repeat) {
        this.$API.postData('/activation', { key: this.activation_key, firstname: this.firstname, lastname: this.lastname, phone: this.phone, password: this.password, password_repeat: this.password_repeat })
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/')
                }
            });
      }

    }
  }
}
</script>