<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100"
    leave-to-class="opacity-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    </transition>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <transition
    enter-class="ease-out duration-300"
    enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-class="ease-in duration-200"
    leave-active-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="mt-3  sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
             {{ $t('modals.edit_sensor') }}
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500 mb-10">
              {{ $t('modals.edit_sensor_description') }}
            </p>
            <div class="mb-5">
                <label for="name" class="block text-sm font-medium text-gray-700">{{ $t('general.name') }}</label>
                <div class="mt-1">
                    <input readonly v-model="sensor.name" type="text" name="name" id="name" class="bg-gray-100 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('general.name')" aria-describedby="email-description">
                </div>
            </div>
            <div class="mb-5">
                <label for="friendly_name" class="block text-sm font-medium text-gray-700">{{ $t('general.name') }}</label>
                <div class="mt-1">
                    <input v-model="sensor.friendly_name" type="text" name="friendly_name" id="friendly_name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('general.name')" aria-describedby="email-description">
                </div>
            </div>
            <div class="mb-5">
                <label for="unit" class="block text-sm font-medium text-gray-700">{{ $t('general.unit') }}</label>
                <div class="mt-1">
                    <input v-model="sensor.unit" type="text" name="unit" id="unit" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('general.unit')" aria-describedby="email-description">
                </div>
            </div>
            <div class="mb-5">
                <label for="comparision_operator" class="block text-sm font-medium text-gray-700">{{ $t('general.comparision_operator') }}</label>
                <select v-model="sensor.comparision_operator" id="comparision_operator" name="comparision_operator" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option value="" selected>{{ $t('general.no_comparision') }}</option>
                    <option value=">">{{ $t('general.gt_than') }}</option>
                    <option value="<">{{ $t('general.lt_than') }}</option>
                    <option value="=">{{ $t('general.is_equal') }}</option>
                    <option value="!=">{{ $t('general.is_not_equal') }}</option>
                </select>
            </div>
            <div>
                <label for="comparision_value" class="block text-sm font-medium text-gray-700">{{ $t('general.comparision_value') }}</label>
                <div class="mt-1">
                    <input v-model="sensor.comparision_value" type="number" name="comparision_value" id="comparision_value" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="$t('general.comparision_value')" aria-describedby="email-description">
                </div>
            </div>

            <div>
              <div class="mt-6">
                    <fieldset>
                      <legend class="text-base font-medium text-gray-900">
                        {{$t('modals.remove')}} {{sensor.name}}?
                      </legend>
                      <div class="mt-4 space-y-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="sensor.remove" id="remove"  type="checkbox" class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remove" class="font-medium text-gray-700">{{$t('modals.confirm_removal')}}</label>
                            <p class="text-gray-500">{{$t('modals.remove_sensor_description')}}</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button @click="saveSensor()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          {{ $t('buttons.save') }}
        </button>
        <button @click="hideModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          {{ $t('buttons.cancel') }}
        </button>
      </div>
    </div>
    </transition>
  </div>
</div>
</template>

<script>

export default {
    name: 'EditSensorModal',
    props: ['sensor'],
    data() {
        return {
          
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide');
        },
        refresh() {
            this.$emit('refresh');
        },
        saveSensor() {
            this.$API.postData('/datalogging/sensor', { hierarchy_id: this.$route.params.id, sensor_id: this.$route.params.mid, name: this.sensor.name, friendly_name: this.sensor.friendly_name, unit: this.sensor.unit, comparision_operator: this.sensor.comparision_operator, comparision_value: this.sensor.comparision_value, remove: this.sensor.remove, id: this.sensor.id })
            .then(response => {
                if(response.status == 200) {
                    this.hideModal()
                    this.refresh()
                    //this.$router.push('/plant/'+response.data.node.id);
                }
            });
        }
    }
}
</script>